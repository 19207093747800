import mock from 'src/services/api/mock/mockAdapter';

import { mockDate } from './utilities';

const people = {
  1: {
    id: 1,
    nif: '33333333J',
    name: 'Juan',
    surname: 'Pérez',
    phone: '333333333',
    email: 'juanpe@gmail.com',
    updated: mockDate(),
    rol: 'Titular'
  },
  2: {
    id: 2,
    nif: '44444444K',
    name: 'Luis',
    surname: 'Sánchez',
    phone: '444444444',
    email: 'luissan@gmail.com',
    updated: mockDate(),
    rol: 'Titular'
  },
  3: {
    id: 3,
    nif: '55555555L',
    name: 'María',
    surname: 'López',
    phone: '555555555',
    email: 'marilo@gmail.com',
    updated: mockDate(),
    rol: 'Representante'
  },
  4: {
    id: 4,
    nif: '66666666M',
    name: 'Ana',
    surname: 'Pérez',
    phone: '666666666',
    email: 'anape@gmail.com',
    updated: mockDate(),
    rol: 'Titular'
  },
  5: {
    id: '8c4cf463-5e37-4e2d-bcda-2985e2c04d27',
    nif: '66666666M',
    name: 'Ana',
    surname: 'Pérez',
    phone: '666666666',
    email: 'anape@gmail.com',
    updated: mockDate(),
    rol: 'Titular'
  }
};

mock.onGet('/api/people').reply(200, {
  data: Object.keys(people).map(key => people[key]),
  page: 0,
  totalCount: Object.keys(people).length
});

Object.keys(people).forEach(key => {
  mock.onGet(`/api/people/${people[key].id}`).reply(200, {
    data: people[key]
  });
});

mock.onGet(/\/api\/people\?idClient=[0-9]*\/?/).reply(200, {
  data: Object.keys(people).map(key => people[key]),
  page: 0,
  totalCount: Object.keys(people).length
});
