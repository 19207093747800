export default theme => ({
  root: {
    '&:hover input[readonly]': {
      cursor: 'default'
    },
    '& input[readonly] + $notchedOutline': {
      borderStyle: 'dashed'
    },
    '&:hover:not($focused) input[readonly] + $notchedOutline': {
      borderColor:
        // Material-UI does it this way instead of having a variable
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.23)'
          : 'rgba(255, 255, 255, 0.23)'
    },
    '&$focused input[readonly] + $notchedOutline': {
      borderColor:
        // Material-UI does it this way instead of having a variable
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.23)'
          : 'rgba(255, 255, 255, 0.23)'
    }
  }
});
