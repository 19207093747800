import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAuth } from 'bcp-data';

import { LOGIN_PAGE } from 'src/config';
import { InputIcon } from 'src/theme/icons';

const styles = theme => ({
  root: {},
  icon: {
    marginRight: theme.spacing(1)
  }
});

function LogoutButton({
  redirectPath,
  buttonProps,
  iconProps,
  className,
  classes
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
    history.push(redirectPath);
  };

  return (
    <Button
      className={clsx(classes.root, className)}
      color="inherit"
      onClick={handleLogout}
      variant="text"
      {...buttonProps}
    >
      <InputIcon className={classes.icon} {...iconProps} />
      {t('actions.logout')}
    </Button>
  );
}

LogoutButton.defaultProps = {
  redirectPath: LOGIN_PAGE,
  buttonProps: {},
  iconProps: {},
  className: '',
  classes: {}
};

LogoutButton.propTypes = {
  redirectPath: PropTypes.string,
  buttonProps: PropTypes.object,
  iconProps: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    icon: PropTypes.string
  })
};

export default withStyles(styles, { name: 'AmetlamLogoutButton' })(
  LogoutButton
);
