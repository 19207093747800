import * as yup from 'yup';

const positiveInteger = {
  base: yup
    .number()
    .typeError('errorMessages.number')
    .integer('errorMessages.integer')
    .positive('errorMessages.positive')
};
positiveInteger.required = positiveInteger.base.required(
  'errorMessages.required'
);
positiveInteger.optional = yup.lazy(value =>
  value === '' ? yup.mixed() : positiveInteger.base
);

export default positiveInteger;
