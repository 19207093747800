import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { BcpMaterialCoreProvider, GlobalCss } from 'bcp-material-core';
import { DataProvider } from 'bcp-data';
import DayjsUtils from '@date-io/dayjs';
import 'dayjs/locale/es';
import 'dayjs/locale/gl';

import themeProvider from 'src/theme';
import i18n, { defaultLocale } from 'src/i18n';
import dateAdapter from 'src/utils/dateAdapter';
import globalStyles from 'src/theme/globalStyles';
import client, { apiConfig } from 'src/services/api/client';

function AppProviders({ children }) {
  const [currentLocale, setCurrentLocale] = useState(defaultLocale);
  const [localizedTheme, setLocalizedTheme] = useState(null);
  dateAdapter.init(currentLocale);

  i18n.on('languageChanged', lng => {
    setCurrentLocale(lng);
    dateAdapter.setLocale(lng);
  });

  useEffect(() => {
    themeProvider(currentLocale).then(res => {
      setLocalizedTheme(res);
    });
  }, [currentLocale]);

  return !!currentLocale && !!localizedTheme ? (
    <>
      <GlobalCss styles={globalStyles(localizedTheme)} />
      <ThemeProvider theme={localizedTheme}>
        <DataProvider
          apiConfig={apiConfig}
          instance={client}
          locale={currentLocale}
        >
          <MuiPickersUtilsProvider utils={DayjsUtils} locale={currentLocale}>
            <BcpMaterialCoreProvider locale={currentLocale}>
              {children}
            </BcpMaterialCoreProvider>
          </MuiPickersUtilsProvider>
        </DataProvider>
      </ThemeProvider>
    </>
  ) : (
    <></>
  );
}

AppProviders.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProviders;
