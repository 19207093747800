import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from 'src/layouts/Auth';
import Dashboard from 'src/layouts/Dashboard';

import urlConfig from './utils/urlConfig';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={urlConfig.login} />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: urlConfig.login,
        exact: true,
        component: lazy(() => import('src/pages/Login'))
      }
    ]
  },
  {
    route: '*',
    component: Dashboard,
    routes: [
      {
        path: '/inicio',
        component: lazy(() => import('src/pages/Home'))
      },
      {
        path: urlConfig.clients.list,
        exact: true,
        component: lazy(() => import('src/pages/Client/List'))
      },
      {
        path: urlConfig.clients.add,
        exact: true,
        component: lazy(() => import('src/pages/Client/Create'))
      },
      {
        path: urlConfig.clients.edit,
        exact: true,
        component: lazy(() => import('src/pages/Client/Edit'))
      },
      {
        path: urlConfig.clients.plots.list,
        exact: true,
        component: lazy(() => import('src/pages/Client/Plot/List'))
      },
      {
        path: urlConfig.clients.plots.edit,
        exact: true,
        component: lazy(() => import('src/pages/Client/Plot/Edit'))
      },
      {
        path: urlConfig.clients.interventions.add,
        exact: true,
        component: lazy(() => import('src/pages/Client/Interventions/Create'))
      },
      {
        path: urlConfig.clients.interventions.edit,
        exact: true,
        component: lazy(() => import('src/pages/Client/Interventions/Edit'))
      },
      {
        path: urlConfig.clients.interventions.details,
        exact: true,
        component: lazy(() => import('src/pages/Client/Interventions/Details'))
      },
      {
        path: urlConfig.clients.inventories.details,
        exact: true,
        component: lazy(() => import('src/pages/Client/Inventories'))
      },
      {
        path: urlConfig.clients.auctions.details,
        exact: true,
        component: lazy(() => import('src/pages/Client/Auctions'))
      },
      {
        path: urlConfig.clients.auctions.add,
        exact: true,
        component: lazy(() => import('src/pages/Client/Auctions'))
      },
      {
        path: urlConfig.users.list,
        exact: true,
        component: lazy(() => import('src/pages/Users/List'))
      },
      {
        path: urlConfig.logs.list,
        exact: true,
        component: lazy(() => import('src/pages/Logs/List'))
      },
      {
        path: urlConfig.masters.provinces.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/Provinces'))
      },
      {
        path: urlConfig.masters.municipalities.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/Municipalities'))
      },
      {
        path: urlConfig.masters.basins.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/Basins'))
      },
      {
        path: urlConfig.masters.auctionTypes.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/AuctionTypes'))
      },
      {
        path: urlConfig.masters.geographicalDemarcations.list,
        exact: true,
        component: lazy(() =>
          import('src/pages/Masters/GeographicalDemarcations')
        )
      },
      {
        path: urlConfig.masters.sigpacuse.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/Sigpacuses'))
      },
      {
        path: urlConfig.masters.udAmbientalGroups.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/UdAmbientalGroups'))
      },
      {
        path: urlConfig.masters.urbanClassifications.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/UrbanClassifications'))
      },
      {
        path: urlConfig.masters.roadTypes.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/RoadTypes'))
      },
      {
        path: urlConfig.masters.lics.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/Lics'))
      },
      {
        path: urlConfig.masters.senlleiras.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/Senlleiras'))
      },
      {
        path: urlConfig.masters.situations.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/Situations'))
      },
      {
        path: urlConfig.masters.zepas.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/Zepas'))
      },
      {
        path: urlConfig.masters.buyers.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/Buyers'))
      },
      {
        path: urlConfig.masters.interventionTypes.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/InterventionTypes'))
      },
      {
        path: urlConfig.masters.interventionSubtypes.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/InterventionSubtypes'))
      },
      {
        path: urlConfig.masters.auctionMotives.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/AuctionMotives'))
      },
      {
        path: urlConfig.masters.clientTypes.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/ClientTypes'))
      },
      {
        path: urlConfig.masters.priorityHabitats.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/PriorityHabitats'))
      },
      {
        path: urlConfig.masters.species.list,
        exact: true,
        component: lazy(() => import('src/pages/Masters/Species'))
      },
      {
        path: urlConfig.tools.main,
        exact: true,
        component: lazy(() => import('src/pages/Tools/IFrame')) 
      }
    ]
  }
];
