import React from 'react';
import PropTypes from 'prop-types';
import { FormSectionTitle, SwitchControlled } from 'bcp-material-core';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Grid from 'src/components/Common/Grid';
import RemoteSelect from 'src/components/Common/LogicalSelects/RemoteSelect';
import RoleSelect from 'src/components/Common/LogicalSelects/RoleSelect';
import { ROLES } from 'src/config';
import Client from 'src/services/api/entities/Client/model/Client';

function UserForm({
  id,
  isUpdate,
  handleSubmit,
  register,
  errors,
  isDisabled,
  control,
  watch
}) {
  const { t } = useTranslation();
  const role = watch('role');

  return (
    <form id={id} onSubmit={handleSubmit} noValidate>
      <FormSectionTitle title={t('forms.titles.user')} />
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <TextField
            type="text"
            name="userName"
            label={t('labels.username')}
            inputRef={register}
            error={!!errors.userName}
            disabled={isDisabled}
            autoComplete="username"
            required
          />
        </Grid>
        {!isUpdate && (
          <Grid item sm={6} xs={12}>
            <TextField
              type="password"
              name="password"
              label={t('labels.password')}
              inputRef={register}
              error={!!errors.password}
              disabled={isDisabled}
              autoComplete="new-password"
              required
            />
          </Grid>
        )}
        <Grid item sm={6} xs={12}>
          <TextField
            type="email"
            name="email"
            label={t('labels.email')}
            inputRef={register}
            error={!!errors.email}
            disabled={isDisabled}
            autoComplete="email"
            required
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            type="text"
            name="name"
            label={t('labels.givenName')}
            inputRef={register}
            error={!!errors.name}
            disabled={isDisabled}
            autoComplete="given-name"
            required
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            type="text"
            name="firstSurname"
            label={t('labels.familyName_first')}
            inputRef={register}
            error={!!errors.firstSurname}
            disabled={isDisabled}
            autoComplete="family-name"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            type="text"
            name="secondSurname"
            label={t('labels.familyName_second')}
            inputRef={register}
            error={!!errors.secondSurname}
            disabled={isDisabled}
            autoComplete="family-name"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <RoleSelect
            name="role"
            label={t('labels.role')}
            control={control}
            error={!!errors.role}
            disabled={isDisabled}
            autoComplete="on"
            required
          />
        </Grid>
        {role !== ROLES.ADMINISTRATOR && (
          <Grid item sm={6} xs={12}>
            <RemoteSelect
              name="clientsIdentifiers"
              label={t('labels.client')}
              model={Client}
              control={control}
              error={!!errors.clientsIdentifiers}
              disabled={isDisabled}
              multiple
            />
          </Grid>
        )}
        <Grid item sm={6} xs={12}>
          <SwitchControlled
            name="active"
            label={t('labels.active')}
            control={control}
            disabled={isDisabled}
            color="primary"
          />
        </Grid>
      </Grid>
    </form>
  );
}

UserForm.defaultProps = {
  id: 'user-form',
  isUpdate: false,
  isDisabled: false
};

UserForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isUpdate: PropTypes.bool,
  isDisabled: PropTypes.bool
};

export default UserForm;
