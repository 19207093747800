import PropTypes from 'prop-types';
import React from 'react';
import { Select } from 'bcp-material-core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';

import i18nInstance from 'src/i18n';
import { TranslateIcon } from 'src/theme/icons';

const styles = theme => ({
  root: {
    width: 120
  },
  languageSelector: {
    transition: 'all 0.25s ease',
    padding: 0,
    color: 'inherit',
    letterSpacing: '0.05rem',
    fontWeight: 500,
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      left: 6
    },
    '& .MuiSelect-root': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(4),
      textAlign: 'center',
      '&:focus': {
        backgroundColor: 'transparent'
      }
    },
    '& .MuiSelect-icon': {
      display: 'none'
    },
    '&, &:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent'
      }
    },
    '&:hover': {
      backgroundColor: '#26323806'
    }
  }
});

function LanguageSelect({ className, classes }) {
  const { i18n } = useTranslation();
  const handleChange = event => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Select
        name="language"
        label=""
        options={[
          { key: 'es', value: 'Español' },
          { key: 'gl', value: 'Galego' }
        ]}
        value={i18nInstance.language}
        onChange={handleChange}
        startAdornment={<TranslateIcon />}
        hideEmptyOption
        variant="outlined"
        className={classes.languageSelector}
      />
    </div>
  );
}

LanguageSelect.defaultProps = {
  className: '',
  classes: {}
};

LanguageSelect.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    languageSelector: PropTypes.string
  })
};

export default withStyles(styles, { name: 'AmetlamLanguageSelect' })(
  LanguageSelect
);
