import { KeyValue } from 'bcp-data';

import KeyValueSearchForm from 'src/components/Masters/KeyValueSearchForm';
import schema from 'src/components/Masters/RoadTypeForm/schema';
import RoadTypeForm from 'src/components/Masters/RoadTypeForm';
import useModel from 'src/services/api/hooks/useModel';
import mapper from 'src/services/api/entities/masters/mappers/roadTypeMapper';

export default class RoadType extends KeyValue {
  static endpoint = 'roadTypes';

  static apiHook = () => useModel(RoadType);

  static mapper = mapper;

  static form = {
    component: RoadTypeForm,
    schema
  };

  static searchForm = {
    component: KeyValueSearchForm,
    schema
  };

  equivCORWEB;

  code;

  constructor(source, id) {
    super(source);
    this.key = id ?? this.key;
    this.equivCORWEB = source.equivCORWEB;
    this.code = source.code;
  }

  static fromApi(apiModel) {
    return new RoadType(mapper.fromApi(apiModel));
  }

  static toApi(clientModel) {
    return mapper.toApi(clientModel);
  }

  static empty() {
    return { key: '', value: '', equivCORWEB: '', code: '' };
  }
}
