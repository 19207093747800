import * as yup from 'yup';

const units = {
  area: ['m3']
};

const unit = {
  base: (type = 'area') => yup.mixed().oneOf(units[type])
};
unit.required = type => unit.base(type).required('errorMessages.required');
unit.optional = type => unit.base(type);

export default unit;
