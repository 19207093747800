import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';

const NetworkErrorMessage = ({ open, setOpen }) => {
  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        {t('errorMessages.network')}
      </Alert>
    </Snackbar>
  );
};

export default NetworkErrorMessage;
