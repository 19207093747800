import { mockDate } from './utilities';
import mock from './mockAdapter';

const boards = {
  1: {
    id: 1,
    created: mockDate(),
    president: '33333333J',
    secretary: '44444444K',
    vocal1: '55555555L',
    vocal2: '66666666M'
  },
  2: {
    id: 2,
    created: mockDate(),
    president: '55555555L',
    secretary: '66666666M',
    vocal1: '33333333J',
    vocal2: '44444444K'
  }
};

mock.onGet('/api/boards').reply(200, {
  data: Object.keys(boards).map(key => boards[key]),
  page: 0,
  totalCount: Object.keys(boards).length
});

Object.keys(boards).forEach(key => {
  mock.onGet(`/api/boards/${boards[key].id}`).reply(200, {
    data: boards[key]
  });
});

mock.onGet(/\/api\/boards\?idClient=[0-9]*\/?/).reply(200, {
  data: Object.keys(boards).map(key => boards[key]),
  page: 0,
  totalCount: Object.keys(boards).length
});
