import { include } from 'named-urls';

const newElement = 'nuevo';
const editElement = 'editar';
// const showElement = 'ver';

export default {
  login: '/auth/login',
  clients: include('/clientes', {
    list: '',
    add: `${newElement}`,
    edit: `:idClient/${editElement}/:tab?`,
    plots: include(':idClient/parcelas', {
      list: '',
      add: `${newElement}`,
      edit: `:idPlot/${editElement}`
    }),
    interventions: include(':idClient/actuaciones', {
      add: `${newElement}`,
      details: `:idIntervention/:tab?`,
      edit: `:idIntervention/${editElement}`
    }),
    inventories: include(':idClient/inventarios', {
      details: ':idInventory/:tab?'
    }),
    auctions: include(':idClient/subastas', {
      details: ':idAuction/:tab?',
      add: `${newElement}`
    })
  }),
  tools: include('/herramientas', {
    main: ':tool',
    growthSimulator: 'simulador-crecimiento',
    absorptionSimulator: 'calculadora-absorciones',
    resinerxiaTake: 'toma-resinerxia',
    resinerxiaAnalysis: 'analisis-resinerxia'
  }),
  users: include('/usuarios', {
    list: ''
  }),
  logs: include('/ver-logs', {
    list: ''
  }),
  masters: include('/maestros', {
    provinces: include('provincias', {
      list: ''
    }),
    municipalities: include('municipios', {
      list: ''
    }),
    basins: include('bacias', {
      list: ''
    }),
    buyers: include('compradores', {
      list: ''
    }),
    auctionTypes: include('tipos-de-subasta', {
      list: ''
    }),
    geographicalDemarcations: include('demarcaciones', {
      list: ''
    }),
    sigpacuse: include('sigpac', {
      list: ''
    }),
    lics: include('lics', {
      list: ''
    }),
    udAmbientalGroups: include('grupos-ambientales', {
      list: ''
    }),
    urbanClassifications: include('clasificaciones-urbanas', {
      list: ''
    }),
    roadTypes: include('tipos-de-via', {
      list: ''
    }),
    zepas: include('zepas', {
      list: ''
    }),
    senlleiras: include('senlleiras', {
      list: ''
    }),
    situations: include('situaciones', {
      list: ''
    }),
    auctionMotives: include('motivos-subasta', {
      list: ''
    }),
    interventionTypes: include('tipos-de-actuacion', {
      list: ''
    }),
    interventionSubtypes: include('subtipos-de-actuacion', {
      list: ''
    }),
    clientTypes: include('tipos-de-cliente', {
      list: ''
    }),
    priorityHabitats: include('habitats-prioritarios', {
      list: ''
    }),
    species: include('especies', {
      list: ''
    })
  })
};
