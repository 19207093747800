import * as yup from 'yup';

import regex from 'src/utils/regex';

const fax = {
  base: yup
    .string()
    .typeError('errorMessages.string')
    .matches(regex.fax, {
      excludeEmptyString: true,
      message: 'errorMessages.fax'
    })
};
fax.required = fax.base.required();
fax.optional = fax.base;

export default fax;
