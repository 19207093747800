import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField
} from '@material-ui/core';
import {
  SwitchControlled,
  useYupValidationResolver,
  Button
} from 'bcp-material-core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Grid from 'src/components/Common/Grid';
import RoleSelect from 'src/components/Common/LogicalSelects/RoleSelect';
import schema from 'src/components/User/UserSearchForm/schema';

function UserSearchForm({ onSearch, isLoading, defaultValue }) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    reset,
    register,
    control,
    errors,
    isDisabled
  } = useForm({
    validationResolver: useYupValidationResolver(schema),
    defaultValues: defaultValue
  });

  return (
    <form onSubmit={handleSubmit(onSearch)}>
      <Card>
        <CardHeader title={t('forms.titles.search')} />
        <Divider component="hr" />
        <CardContent component="div">
          <Grid container spacing={3}>
            <Grid item md={9} sm={6} xs={12}>
              <TextField
                type="text"
                name="name"
                label={t('labels.givenName')}
                inputRef={register}
                error={!!errors.name}
                disabled={isDisabled}
                autoComplete="given-name"
              />
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <RoleSelect
                name="role"
                control={control}
                label={t('labels.role')}
                autoComplete="on"
              />
            </Grid>
            <Grid item md={1} sm={6} xs={12}>
              <SwitchControlled
                name="active"
                label={t('labels.active')}
                control={control}
                color="primary"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider component="hr" />
        <CardActions>
          <Button onClick={() => reset(defaultValue)} disabled={isLoading}>
            {t('actions.reset')}
          </Button>
          <Button type="submit" color="primary" isLoading={isLoading}>
            {t('actions.search')}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}

UserSearchForm.propTypes = {
  onSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  defaultValue: PropTypes.any
};

UserSearchForm.defaultProps = {
  onSearch: null,
  isLoading: false,
  defaultValue: ''
};

export default UserSearchForm;
