import mapper from 'object-mapper';

const fromApiMap = {
  id: 'id',
  userName: 'userName',
  password: 'password',
  email: 'email',
  name: 'name',
  firstSurname: 'firstSurname',
  secondSurname: 'secondSurname',
  roleName: 'roleName',
  role: 'role',
  active: 'active',
  clients: [
    {
      key: 'clients'
    },
    {
      key: 'clientsIdentifiers',
      transform: (srcValue, srcObject) => {
        if (srcValue && !srcObject?.clientsIdentifiers) {
          return srcValue.map(client => client?.id);
        }
        return undefined;
      }
    }
  ],
  clientsIdentifiers: 'clientsIdentifiers'
};

const toApiMap = {
  id: 'id',
  userName: 'userName',
  password: 'password',
  email: 'email',
  name: 'name',
  firstSurname: 'firstSurname',
  secondSurname: 'secondSurname',
  roleName: 'roleName',
  role: 'role',
  active: 'active',
  clients: 'clients',
  clientsIdentifiers: 'clientsIdentifiers'
};

export default {
  toApi: src => mapper(src, toApiMap),
  fromApi: src => mapper(src, fromApiMap),
  maps: {
    fromApiMap,
    toApiMap
  }
};
