import { BaseModel } from 'bcp-data';

import MunicipalityForm from 'src/components/Masters/MunicipalityForm';
import schema from 'src/components/Masters/MunicipalityForm/schema';
import MunicipalitySearchForm from 'src/components/Masters/MunicipalitySearchForm';
import searchSchema from 'src/components/Masters/MunicipalitySearchForm/schema';
import useModel from 'src/services/api/hooks/useModel';
import mapper from 'src/services/api/entities/masters/mappers/municipalityMapper';

export default class Municipality extends BaseModel {
  static endpoint = 'municipalities';

  static apiHook = () => useModel(Municipality);

  static mapper = mapper;

  static form = {
    component: MunicipalityForm,
    schema
  };

  static searchForm = {
    component: MunicipalitySearchForm,
    schema: searchSchema
  };

  id;

  name;

  provinceId;

  provinceName;

  codCORWEB;

  constructor(source, id) {
    super(source);
    this.key = id ?? this.key;
  }

  static fromApi(apiModel) {
    return new Municipality(mapper.fromApi(apiModel));
  }

  static toApi(clientModel) {
    return mapper.toApi(clientModel);
  }

  static toKeyValue(clientModel) {
    return {
      key: clientModel.id,
      value: clientModel.name
    };
  }

  static empty() {
    return { id: '', name: '', provinceId: '', provinceName: '' , codCORWEB: 0};
  }
}
