import mock from 'src/services/api/mock/mockAdapter';

import { mockDate } from './utilities';

const interventions = {
  1: {
    id: 1,
    name: 'Desbrozar',
    description: 'Desbrózanse os terrenos',
    date: mockDate(),
    annotation: '',
    plots: [
      'b0c1f199-25aa-4bd9-cebc-08d800c42ab3',
      '4ab17852-c8fe-4ccc-cebe-08d800c42ab3'
    ]
  },
  2: {
    id: 2,
    name: 'Podar',
    description: 'Pódanse as árbores',
    date: mockDate(),
    annotation: '',
    plots: [
      '10bb4e07-e57a-411c-cebd-08d800c42ab3',
      '9390c263-efde-40f5-cebf-08d800c42ab3'
    ]
  },
  3: {
    id: 3,
    name: 'Plantar',
    description: 'Plántanse máis árbores',
    date: mockDate(),
    annotation: '',
    plots: [
      'b0c1f199-25aa-4bd9-cebc-08d800c42ab3',
      '9390c263-efde-40f5-cebf-08d800c42ab3',
      '4ab17852-c8fe-4ccc-cebe-08d800c42ab3'
    ]
  }
};

mock.onGet('/api/interventions').reply(200, {
  data: Object.keys(interventions).map(key => interventions[key]),
  page: 0,
  totalCount: Object.keys(interventions).length
});

Object.keys(interventions).forEach(key => {
  mock.onGet(`/api/interventions/${interventions[key].id}`).reply(200, {
    data: interventions[key]
  });
});

mock.onGet(/\/api\/interventions\?idClient=[0-9]*\/?/).reply(200, {
  data: Object.keys(interventions).map(key => interventions[key]),
  page: 0,
  totalCount: Object.keys(interventions).length
});

mock.onGet(/\/api\/interventions\?idPlot=[0-9]*\/?/).reply(200, {
  data: Object.keys(interventions).map(key => interventions[key]),
  page: 0,
  totalCount: Object.keys(interventions).length
});
