export default theme => ({
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '& $icon': {
      color: theme.palette.primary.contrastText
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: '#ffffff'
    }
  }
});
