import React from 'react';
import PropTypes from 'prop-types';
import {
  DatePickerControlled,
  FormSectionTitle,
  SelectControlled,
  SwitchControlled
} from 'bcp-material-core';
import { TextField, Button, InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Criteria } from 'bcp-data';
import { makeStyles } from '@material-ui/core/styles';

import Grid from 'src/components/Common/Grid';
import { getRepresentativeTypesForSelect, REINVESTMENT_URL } from 'src/config';
import ClientType from 'src/services/api/entities/masters/models/ClientType';
import { LaunchIcon } from 'src/theme/icons';
import RemoteSelect from 'src/components/Common/LogicalSelects/RemoteSelect';
import Municipality from 'src/services/api/entities/masters/models/Municipality';
import Province from 'src/services/api/entities/masters/models/Province';
import RoadType from 'src/services/api/entities/masters/models/RoadType';
import dateAdapter from 'src/utils/dateAdapter';

const styles = makeStyles(() => ({ menuPaper: { maxHeight: '50%' }, lblDisabled: {color: 'grey'} }));

function ClientForm({
  id,
  isUpdate,
  handleSubmit,
  register,
  errors,
  isDisabled,
  control,
  watch,
}) {
  const { t } = useTranslation();

  const showCio = watch('hasCio');
  const selectedProvince = watch('provinceId');
  const selectedMunicipality = watch('municipalityId');
  const corwebPassRenewedDate = watch('corwebRenewedDate');
  const classes = styles();

  return (
    <form id={id} onSubmit={handleSubmit} noValidate>
      <FormSectionTitle
        title={t('forms.titles.dataOf', { what: t('common.client') })}
      />
      <Grid container spacing={3}>
        <Grid item md={2} sm={6} xs={12}>
          <TextField
            type="text"
            name="clientId"
            label={`ID ${t('common.client')}`}
            inputRef={register}
            error={!!errors.clientId}
            disabled={isDisabled}
            InputLabelProps={{ className: isUpdate ? 'readOnly' : undefined }}
            autoComplete="on"
            required
            helperText={t(errors?.clientId?.message) || ''}
          />
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <RemoteSelect
            model={ClientType}
            name="clientTypeId"
            label={t('labels.type')}
            error={!!errors.clientTypeId}
            disabled={isDisabled}
            bypassDisabled
            control={control}
            hideEmptyOption
            autoComplete="on"
            required
            helperText={t(errors?.clientTypeId?.message) || ''}
          />
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <TextField
            type="text"
            name="codeUGF"
            label={t('labels.codeUGF')}
            inputRef={register}
            error={!!errors.codeUGF}
            disabled={isDisabled}
            autoComplete="on"
            helperText={t(errors?.codeUGF?.message) || ''}
          />
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <TextField
            type="text"
            name="numberCertificateForest"
            label={t('labels.numberCertificateForest')}
            inputRef={register}
            error={!!errors.numberCertificateForest}
            disabled={isDisabled}
            autoComplete="on"
            helperText={t(errors?.numberCertificateForest?.message) || ''}
          />
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <DatePickerControlled
            name="certificateForestDate"
            control={control}
            disabled={isDisabled}
            component="keyboard"
            variant="inline"
            label={t('labels.dateOf', {
              what: t('labels.certificateForestDate')
            })}
            format={dateAdapter.defaultFormat}
            autoOk
            error={
              !!errors.certificateForestDate ||
              !!errors?.['certificateForestDate.$y']
            }
            helperText={
              t(errors?.certificateForestDate?.message) ||
              (errors?.['certificateForestDate.$y'] ? 'Formato Inválido' : '')
            }
          />
        </Grid>
        <Grid item md={2} sm={2} xs={12}>
          <SwitchControlled
            name="hasCio"
            label={t('common.has', { what: t('common.cio') })}
            labelPlacement="top"
            control={control}
            disabled={isDisabled}
            color="primary"
          />
        </Grid>
        {showCio && (
          <Grid item md={2} sm={5} xs={12}>
            <TextField
              type="text"
              name="cio"
              label={t('labels.cio')}
              inputRef={register}
              error={!!errors.cio}
              disabled={isDisabled}
              autoComplete="on"
              helperText={t(errors?.cio?.message) || ''}
            />
          </Grid>
        )}

        <Grid item sm={12} xs={12}>
          <hr />
        </Grid>

        <Grid item md={2} sm={6} xs={12}>
          <TextField
            type="text"
            name="name"
            label={t('labels.nameSocial')}
            inputRef={register}
            error={!!errors.name}
            disabled={isDisabled}
            autoComplete="on"
            required
            helperText={t(errors?.name?.message) || ''}
          />
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <TextField
            type="text"
            name="firstSurname"
            label={t('labels.familyName_first')}
            inputRef={register}
            error={!!errors.firstSurname}
            disabled={isDisabled}
            autoComplete="family-name"
            helperText={t(errors?.firstSurname?.message) || ''}
          />
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <TextField
            type="text"
            name="secondSurname"
            label={t('labels.familyName_second')}
            inputRef={register}
            error={!!errors.secondSurname}
            disabled={isDisabled}
            autoComplete="family-name"
            helperText={t(errors?.secondSurname?.message) || ''}
          />
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <TextField
            type="text"
            name="nif"
            label={t('labels.nif')}
            inputRef={register}
            error={!!errors.nif}
            disabled={isDisabled}
            autoComplete="on"
            helperText={t(errors?.nif?.message) || ''}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <hr />
        </Grid>

        <Grid item md={2} sm={4} xs={12}>
          <RemoteSelect
            model={RoadType}
            name="roadTypeId"
            label={t('labels.roadType')}
            error={!!errors.roadTypeId}
            disabled={isDisabled}
            bypassDisabled
            control={control}
            hideEmptyOption
            autoComplete="on"
            helperText={t(errors?.roadTypeId?.message) || ''}
          />
        </Grid>
        <Grid item md={3} sm={8} xs={12}>
          <TextField
            type="text"
            name="address"
            label={t('labels.street')}
            inputRef={register}
            error={!!errors.address}
            disabled={isDisabled}
            autoComplete="on"
            helperText={t(errors?.type?.message) || ''}
          />
        </Grid>
        <Grid item md={1} sm={3} xs={12}>
          <TextField
            type="text"
            name="number"
            label={t('labels.streetNumber')}
            inputRef={register}
            error={!!errors.number}
            disabled={isDisabled}
            inputProps={{ min: 0 }}
            autoComplete="on"
            inputMode="numeric"
            helperText={t(errors?.number?.message) || ''}
          />
        </Grid>
        <Grid item md={1} sm={3} xs={12}>
          <TextField
            type="text"
            name="apartmentBlock"
            label={t('labels.apartmentBlock')}
            inputRef={register}
            error={!!errors.apartmentBlock}
            disabled={isDisabled}
            autoComplete="on"
            helperText={t(errors?.apartmentBlock?.message) || ''}
          />
        </Grid>
        <Grid item md={1} sm={3} xs={12}>
          <TextField
            type="text"
            name="floor"
            label={t('labels.floor')}
            inputRef={register}
            error={!!errors.floor}
            disabled={isDisabled}
            autoComplete="on"
            helperText={t(errors?.floor?.message) || ''}
          />
        </Grid>
        <Grid item md={1} sm={3} xs={12}>
          <TextField
            type="text"
            name="door"
            label={t('labels.door')}
            inputRef={register}
            error={!!errors.door}
            disabled={isDisabled}
            autoComplete="on"
            helperText={t(errors?.door?.message) || ''}
          />
        </Grid>

        <Grid item md={3} sm={6} xs={12}>
          <TextField
            type="text"
            name="place"
            label={t('labels.place')}
            inputRef={register}
            error={!!errors.place}
            disabled={isDisabled}
            autoComplete="on"
            helperText={t(errors?.place?.message) || ''}
          />
        </Grid>

        <Grid item md={2} sm={6} xs={12}>
          <RemoteSelect
            model={Province}
            name="provinceId"
            label={t('labels.province')}
            error={!!errors.provinceId}
            control={control}
            disabled={isDisabled}
            bypassDisabled
            hideEmptyOption
            autoComplete="on"
            helperText={t(errors?.provinceId?.message) || ''}
          />
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <RemoteSelect
            model={Municipality}
            name="municipalityId"
            label={t('labels.municipality')}
            error={!!errors.municipalityId}
            disabled={!selectedProvince || isDisabled}
            bypassDisabled
            control={control}
            criteria={new Criteria({ provinceId: selectedProvince })}
            hideEmptyOption
            hasDependency
            autoComplete="on"
            helperText={t(errors?.municipalityId?.message) || ''}
          />
        </Grid>

        <Grid item md={2} sm={6} xs={12}>
        <TextField
            type="text"
            name="parish"
            label={t('labels.parish')}
            inputRef={register}
            error={!!errors.location}
            disabled={isDisabled}
            autoComplete="on"
            helperText={t(errors?.parish?.message) || ''}
          />
        </Grid>

        <Grid item sm={6} md={3} xs={12}>
          <TextField
            type="text"
            name="location"
            label={t('labels.location')}
            inputRef={register}
            error={!!errors.location}
            disabled={isDisabled}
            autoComplete="on"
            helperText={t(errors?.location?.message) || ''}
          />
        </Grid>
        
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            type="text"
            name="postalCode"
            label={t('labels.postalCode')}
            inputRef={register}
            error={!!errors.postalCode}
            disabled={isDisabled}
            autoComplete="on"
            helperText={t(errors?.postalCode?.message) || ''}
          />
        </Grid>

        <Grid item md={2} sm={6} xs={12}>
          <TextField
            type="text"
            name="telephone"
            label={t('labels.telephone')}
            inputRef={register}
            error={!!errors.telephone}
            disabled={isDisabled}
            autoComplete="on"
            helperText={t(errors?.telephone?.message) || ''}
          />
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <TextField
            type="text"
            name="fax"
            label={t('labels.fax')}
            inputRef={register}
            error={!!errors.fax}
            disabled={isDisabled}
            autoComplete="on"
            helperText={t(errors?.fac?.message) || ''}
          />
        </Grid>
        <Grid item md={2} sm={4} xs={12}>
          <TextField
            type="text"
            name="mobile"
            label={t('labels.mobile')}
            inputRef={register}
            error={!!errors.mobile}
            disabled={isDisabled}
            autoComplete="on"
            helperText={t(errors?.mobile?.message) || ''}
          />
        </Grid>
        <Grid item md={3} sm={8} xs={12}>
          <TextField
            type="text"
            name="email"
            label={t('labels.email')}
            inputRef={register}
            error={!!errors.email}
            disabled={isDisabled}
            autoComplete="on"
            helperText={t(errors?.email?.message) || ''}
          />
        </Grid>
        <Grid item md={3} sm={12} xs={12}>
          <TextField
            type="text"
            name="mountName"
            label={t('labels.mountName')}
            inputRef={register}
            error={!!errors.mountName}
            disabled={isDisabled}
            autoComplete="on"
            helperText={t(errors?.mountName?.message) || ''}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <hr />
        </Grid>

        {isUpdate && (
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              type="text"
              name="pathArgis"
              label={t('labels.pathArgis')}
              inputRef={register}
              error={!!errors.pathArgis}
              disabled={isDisabled}
              bypassDisabled
              InputLabelProps={{ className: 'readOnly' }}
              autoComplete="on"
              helperText={t(errors?.pathArgis?.message) || ''}
            />
          </Grid>
        )}
        <Grid item md={2} sm={6} xs={12}>
          <TextField
            type="number"
            name="area"
            label={t('labels.surface')}
            InputProps={{
              endAdornment: (
                <InputAdornment className={classes.lblDisabled} position="end" component="div">
                  Ha
                </InputAdornment>
              )
            }}
            inputRef={register}
            error={!!errors.area}
            disabled={isDisabled}
            autoComplete="on"
            inputMode="decimal"
            helperText={t(errors?.area?.message) || ''}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <FormSectionTitle
            title={t('forms.titles.dataOf', {
              what: t('common.representative')
            })}
          />
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <TextField
            type="text"
            name="representative.name"
            label={t('labels.nameSocial')}
            inputRef={register}
            error={!!errors?.['representative.name']}
            disabled={isDisabled}
            autoComplete="on"
            helperText={t(errors?.['representative.name']?.message) || ''}
          />
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <TextField
            type="text"
            name="representative.firstSurname"
            label={t('labels.familyName_first')}
            inputRef={register}
            error={!!errors?.['representative.firstSurname']}
            disabled={isDisabled}
            autoComplete="family-name"
            helperText={
              t(errors?.['representative.firstSurname']?.message) || ''
            }
          />
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <TextField
            type="text"
            name="representative.secondSurname"
            label={t('labels.familyName_second')}
            inputRef={register}
            error={!!errors?.['representative.secondSurname']}
            disabled={isDisabled}
            autoComplete="family-name"
            helperText={
              t(errors?.['representative.secondSurname']?.message) || ''
            }
          />
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <TextField
            type="text"
            name="representative.nif"
            label={t('labels.nif')}
            inputRef={register}
            error={!!errors?.['representative.nif']}
            disabled={isDisabled}
            autocomlpete="on"
            helperText={t(errors?.['representative.nif']?.message) || ''}
          />
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <SelectControlled
            name="representative.representativeType"
            label={`${t('labels.representativeType')}:`}
            options={getRepresentativeTypesForSelect(t)}
            error={!!errors?.['representative.representativeType']}
            control={control}
            disabled={isDisabled}
            hideEmptyOption
            autoComplete="on"
            helperText={
              t(errors?.['representative.representativeType']?.message) || ''
            }
            MenuProps={{ classes: { paper: classes.menuPaper } }}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <FormSectionTitle
            title={t('forms.titles.dataOf', {
              what: t('labels.reinvestment')
            })}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <DatePickerControlled
            name="reinvestmentValidity"
            control={control}
            disabled={isDisabled}
            component="keyboard"
            variant="inline"
            label={t('labels.dateOf', {
              what: t('labels.validity')
            })}
            format={dateAdapter.defaultFormat}
            autoOk
            error={
              !!errors.reinvestmentValidity ||
              !!errors?.['reinvestmentValidity.$y']
            }
            helperText={
              t(errors?.reinvestmentValidity?.message) ||
              (errors?.['reinvestmentValidity.$y'] ? 'Formato Inválido' : '')
            }
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <DatePickerControlled
            name="lastReinvestmentCommunication"
            control={control}
            disabled={isDisabled}
            component="keyboard"
            variant="inline"
            label={t('labels.dateOf', {
              what: t('labels.communication_last')
            })}
            format={dateAdapter.defaultFormat}
            autoOk
            error={
              !!errors.lastReinvestmentCommunication ||
              !!errors?.['lastReinvestmentCommunication.$y']
            }
            helperText={
              t(errors?.lastReinvestmentCommunication?.message) ||
              (errors?.['lastReinvestmentCommunication.$y']
                ? 'Formato Inválido'
                : '')
            }
          />
        </Grid>
        {isUpdate && (
          <Grid
            item
            md={4}
            sm={12}
            xs={12}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Button
              href={REINVESTMENT_URL}
              color="primary"
              endIcon={<LaunchIcon component="svg" />}
              target="_blank"
            >
              {t('navigation.goto', {
                context: 'female',
                where: t('common.web')
              })}
            </Button>
          </Grid>
        )}


 
        <Grid item sm={12} xs={12}>
          <FormSectionTitle title={t('forms.titles.dataOf') + ' Corweb'} />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            type="text"
            name="corwebUser"
            label={t('labels.username')}
            inputRef={register}
            error={!!errors?.['corwebUser']}
            disabled={isDisabled}
            autocomplete="on"
            helperText={t(errors?.['corwebUser']?.message) || ''}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            type="password"
            name="corwebPassword"
            label={t('labels.password')}
            inputRef={register}
            error={!!errors?.['corwebPassword']}
            disabled={isDisabled}
            helperText={t(errors?.['corwebPassword']?.message) || ''}
          />
        </Grid>        
        <Grid item md={3} sm={6} xs={12}>
        <DatePickerControlled
            name="corwebRenewedDate"
            control={control}
            label={t('labels.dateOf', {
              what: t('labels.passwordRenewed', {lowercase: true})
            })}
            component="keyboard"
            format={dateAdapter.defaultFormat}
            variant={'inline'}
            disabled={isDisabled}
            defaultValue={null}
            autoOk
            error={!!errors?.['corwebRenewedDate.$y']}
            helperText={errors?.['corwebRenewedDate.$y'] ? 'Formato Inválido' : ''}
          />
        </Grid>
        {corwebPassRenewedDate && <Grid item md={3} sm={6} xs={12}>
          <TextField
            type="text"
            label={t('labels.dateOf', {
              what: t('labels.passwordExpiry', {lowercase:true})
            })}
            value={dateAdapter.create(corwebPassRenewedDate).add(4,'year').format("DD/MM/YYYY")}
            disabled
          />
        </Grid> }
        
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            type="password"
            name="corwebReadPassword"
            label={t('labels.passwordRead')}
            inputRef={register}
            error={!!errors?.['corwebReadPassword']}
            disabled={isDisabled}
            helperText={t(errors?.['corwebReadPassword']?.message) || ''}
          />
        </Grid>     

        {/* @TODO: Corregir cuando esté bien definida la sección */}
        <Grid item sm={12} xs={12}>
          <FormSectionTitle title={t('forms.titles.other')} />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <DatePickerControlled
            name="boardMeetingDate"
            control={control}
            disabled={isDisabled}
            component="keyboard"
            variant="inline"
            label={t('labels.dateOf', {
              what: t('labels.boardMeeting')
            })}
            format={dateAdapter.defaultFormat}
            autoOk
            error={
              !!errors.boardMeetingDate || !!errors?.['boardMeetingDate.$y']
            }
            helperText={
              t(errors?.boardMeetingDate?.message) ||
              (errors?.['boardMeetingDate.$y'] ? 'Formato Inválido' : '')
            }
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <DatePickerControlled
            name="censusUpdateDate"
            control={control}
            disabled={isDisabled}
            component="keyboard"
            variant="inline"
            label={t('labels.dateOf', {
              what: t('labels.censusUpdate')
            })}
            format={dateAdapter.defaultFormat}
            autoOk
            error={
              !!errors.censusUpdateDate || !!errors?.['censusUpdateDate.$y']
            }
            helperText={
              t(errors?.censusUpdateDate?.message) ||
              (errors?.['censusUpdateDate.$y'] ? 'Formato Inválido' : '')
            }
          />
        </Grid>
      </Grid>
    </form>
  );
}

ClientForm.defaultProps = {
  id: 'client-form',
  isUpdate: false,
  isDisabled: false
};

ClientForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isUpdate: PropTypes.bool,
  isDisabled: PropTypes.bool
};

export default ClientForm;
