import React, { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';

function Auth({ route }) {
  return (
    <Suspense fallback={<LinearProgress />}>
      {renderRoutes(route.routes)}
    </Suspense>
  );
}

Auth.propTypes = {
  route: PropTypes.object.isRequired
};

export default Auth;
