import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Hidden } from '@material-ui/core';
import { useAuth } from 'bcp-data';

import LanguageSelect from 'src/components/Common/LanguageSelect';
import LogoutButton from 'src/components/Common/LogoutButton';
import UserSnack from 'src/components/Common/UserSnack';
import User from 'src/services/api/entities/User/model/User';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`
  }
}));

function NavBarButtons() {
  const classes = useStyles();
  const { user } = useAuth();

  return (
    <div className={classes.root}>
      <Hidden mdUp>
        <LanguageSelect />
      </Hidden>
      {user?.id && (
        <Hidden smUp>
          <UserSnack user={User.fromApi(user)} />
        </Hidden>
      )}
      <Hidden mdUp>
        <LogoutButton />
      </Hidden>
    </div>
  );
}

export default NavBarButtons;
