import mapper from 'object-mapper';

import Municipality from 'src/services/api/entities/masters/models/Municipality';
import Province from 'src/services/api/entities/masters/models/Province';
import dateAdapter from 'src/utils/dateAdapter';

const fromApiMap = {
  id: 'id',
  clientId: 'clientId',
  clientTypeName: 'clientTypeName',
  clientTypeId: 'clientTypeId',
  cio: 'cio',
  codeUGF: 'codeUGF',
  numberCertificateForest: 'numberCertificateForest',
  name: 'name',
  firstSurname: 'firstSurname',
  secondSurname: 'secondSurname',
  nif: 'nif',
  address: 'address',
  provinceId: 'provinceId',
  provinceName: 'provinceName',
  province: {
    key: 'province',
    transform: sourceValue =>
      sourceValue ? Province.fromApi(sourceValue) : undefined
  },
  municipalityId: 'municipalityId',
  municipalityName: 'municipalityName',
  municipality: {
    key: 'municipality',
    transform: sourceValue =>
      sourceValue ? Municipality.fromApi(sourceValue) : undefined
  },
  parish: 'parish',
  place: 'place',
  number: 'number',
  apartmentBlock: 'apartmentBlock',
  floor: 'floor',
  door: 'door',
  postalCode: 'postalCode',
  location: 'location',
  roadTypeId: 'roadTypeId',
  telephone: 'telephone',
  fax: 'fax',
  mobile: 'mobile',
  email: 'email',
  area: 'area',
  reinvestmentValidity: {
    key: 'reinvestmentValidity',
    transform: dateAdapter.fromApi
  },
  lastReinvestmentCommunication: {
    key: 'lastReinvestmentCommunication',
    transform: dateAdapter.fromApi
  },
  boardMeetingDate: {
    key: 'boardMeetingDate',
    transform: dateAdapter.fromApi
  },
  censusUpdateDate: {
    key: 'censusUpdateDate',
    transform: dateAdapter.fromApi
  },
  pathArgis: 'pathArgis',
  representative: 'representative',
  mountName: 'mountName',
  certificateForestDate: {
    key: 'certificateForestDate',
    transform: dateAdapter.fromApi
  },
  corwebUser: 'corwebUser',
  corwebPassword: 'corwebPassword',  
  corwebReadPassword: 'corwebReadPassword',  
  corwebRenewedDate: {
    key: 'corwebRenewedDate',
    transform: dateAdapter.fromApi
  },
};


const toApiMap = {
  id: 'id',
  clientId: 'clientId',
  clientTypeName: 'clientTypeName',
  clientTypeId: 'clientTypeId',
  cio: 'cio',
  codeUGF: 'codeUGF',
  numberCertificateForest: 'numberCertificateForest',
  name: 'name',
  firstSurname: 'firstSurname',
  secondSurname: 'secondSurname',
  nif: 'nif',
  address: 'address',
  provinceId: 'provinceId',
  municipalityId: 'municipalityId',
  parish: 'parish',
  place: 'place',
  number: 'number',
  apartmentBlock: 'apartmentBlock',
  floor: 'floor',
  door: 'door',
  postalCode: 'postalCode',
  location: 'location',
  roadTypeId: 'roadTypeId',
  telephone: 'telephone',
  fax: 'fax',
  mobile: 'mobile',
  email: 'email',
  area: 'area',
  reinvestmentValidity: {
    key: 'reinvestmentValidity',
    transform: dateAdapter.toApi
  },
  lastReinvestmentCommunication: {
    key: 'lastReinvestmentCommunication',
    transform: dateAdapter.toApi
  },
  boardMeetingDate: {
    key: 'boardMeetingDate',
    transform: dateAdapter.toApi
  },
  censusUpdateDate: {
    key: 'censusUpdateDate',
    transform: dateAdapter.toApi
  },
  pathArgis: 'pathArgis',
  representative: 'representative',
  mountName: 'mountName',
  certificateForestDate: {
    key: 'certificateForestDate',
    transform: dateAdapter.toApi
  },
  corwebUser: 'corwebUser',
  corwebPassword: 'corwebPassword',
  corwebReadPassword: 'corwebReadPassword',
  corwebRenewedDate: {
    key: 'corwebRenewedDate',
    transform: dateAdapter.toApi
  },
};

export default {
  toApi: src => mapper(src, toApiMap),
  fromApi: src => mapper(src, fromApiMap),
  maps: {
    fromApiMap,
    toApiMap
  }
};
