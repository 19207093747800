import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { Layout } from 'bcp-material-views';

import { HOME_PAGE, LOGO } from 'src/config';
import useAccessLevel from 'src/hooks/useAccessLevel';

import TopBarBtns from './TopBarBtns';
import NavBarButtons from './NavBarButtons';
import navConfig from './navConfig';

function Dashboard({ route }) {
  const { t } = useTranslation();
  const { hasRole } = useAccessLevel();

  const hasAccess = item =>
    !item?.requiredAccessRole || hasRole(item?.requiredAccessRole);

  const hasSubmenu = item => item?.items;

  const withoutForbiddenNavigation = nav =>
    Object.values(
      nav.reduce((filteredNav, currentItem, currentIndex) => {
        if (hasAccess(currentItem)) {
          if (hasSubmenu(currentItem)) {
            const filteredSubmenu = withoutForbiddenNavigation(
              currentItem.items
            );
            if (!filteredSubmenu?.length && !currentItem?.href) {
              return filteredNav;
            }
            return {
              ...filteredNav,
              [currentIndex]: {
                ...currentItem,
                items: filteredSubmenu
              }
            };
          }
          return {
            ...filteredNav,
            [currentIndex]: currentItem
          };
        }
        return filteredNav;
      }, {})
    );

  const logo = (
    <Link to={HOME_PAGE}>
      <img alt="Logo" src={LOGO} />
    </Link>
  );

  return (
    <Layout
      logo={logo}
      topBarSections={[<TopBarBtns />]}
      navProps={{
        config: withoutForbiddenNavigation(navConfig(t)),
        profile: <NavBarButtons />
      }}
    >
      {renderRoutes(route.routes)}
    </Layout>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object.isRequired
};

export default Dashboard;
