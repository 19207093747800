import MuiCardActions from './MuiCardActions';
import MuiCard from './MuiCard';
import MuiFormLabel from './MuiFormLabel';
import MuiInputLabel from './MuiInputLabel';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiBreadcrumbs from './MuiBreadcrumbs';
import BcpNavItem from './BcpNavItem';

const toOverride = {
  MuiCard,
  MuiCardActions,
  MuiFormLabel,
  MuiInputLabel,
  MuiOutlinedInput,
  MuiBreadcrumbs,
  BcpNavItem
};

const overrides = {};

export default theme => {
  Object.keys(toOverride).forEach(key => {
    overrides[key] =
      typeof toOverride[key] === 'function'
        ? toOverride[key](theme)
        : toOverride[key];
  });
  return overrides;
};
