import { colors } from '@material-ui/core';

const white = '#FFFFFF';

export default {
  primary: {
    contrastText: white,
    dark: '#0089a0',
    main: '#00ACC9',
    light: '#00c4e6'
  },
  secondary: {
    contrastText: white,
    dark: colors.lightBlue[900],
    main: colors.lightBlue.A700,
    light: colors.lightBlue.A400
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.lightBlue[600]
  },
  link: colors.lightBlue[800],
  background: {
    default: '#F4F6F8',
    paper: white
  },
  divider: colors.grey[200]
};
