import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Hidden } from '@material-ui/core';
import { useAuth } from 'bcp-data';

import LanguageSelect from 'src/components/Common/LanguageSelect';
import LogoutButton from 'src/components/Common/LogoutButton';
import UserSnack from 'src/components/Common/UserSnack';
import User from 'src/services/api/entities/User/model/User';

const useStyles = makeStyles(theme => ({
  languageSelect: {
    marginLeft: theme.spacing(1)
  },
  userSnack: {
    marginLeft: theme.spacing(1)
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  }
}));

function TopBarBtns() {
  const classes = useStyles();
  const { user } = useAuth();

  return (
    <>
      <Hidden smDown>
        <LanguageSelect className={classes.languageSelect} />
      </Hidden>
      {user?.id && (
        <Hidden xsDown>
          <UserSnack user={User.fromApi(user)} className={classes.userSnack} />
        </Hidden>
      )}
      <Hidden smDown>
        <LogoutButton className={classes.logoutButton} />
      </Hidden>
    </>
  );
}

export default TopBarBtns;
