/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import Logger from 'src/utils/Logger';

const DATE_FORMAT = 'DD/MM/YYYY';

const init = locale => {
  dayjs.locale(locale);
  dayjs.extend(customParseFormat);
};

const setLocale = locale => {
  dayjs.locale(locale);
};

const getLocale = () => dayjs.locale();

const create = dateInput => dayjs(dateInput);

const format = (date, _format = DATE_FORMAT) => dayjs(date).format(_format);

const timestampFormats = {
  M: 'milliseconds',
  S: 'seconds'
};
const getTimestamp = (date, _format = timestampFormats.M) => {
  const formatToUse = Object.keys(timestampFormats).includes(_format)
    ? timestampFormats[_format]
    : timestampFormats.M;
  switch (formatToUse) {
    case 'milliseconds':
      return dayjs(date).valueOf();
    case 'seconds':
      return dayjs(date).unix();
    default:
      Logger.error(`No specification for the format ${_format}.`);
      return null;
  }
};

const fromApi = src => {
  if (!src) return undefined;
  return create(src);
};

const toApi = date => {
  if (!date) return undefined;
  return dayjs(date).toISOString();
};

export default {
  defaultFormat: DATE_FORMAT,
  init,
  setLocale,
  getLocale,
  create,
  formatDate: format,
  getTimestamp,
  fromApi,
  toApi
};
