import { useAuth } from 'bcp-data';

function useRole() {
  const { user } = useAuth();

  const hasRole = roleIds => {
    if (!user || !user.role) return false;
    if (Array.isArray(roleIds)) {
      return roleIds.some(roleId => user.role.id === roleId);
    }
    return user.role.id === roleIds;
  };

  return {
    role: user.role,
    hasRole
  };
}

export default useRole;
