import * as yup from 'yup';

import regex from 'src/utils/regex';

const telephone = {
  base: yup
    .string()
    .typeError('errorMessages.string')
};
telephone.required = telephone.base
  .required('errorMessages.required')
  .test('starts-with-9', 'errorMessages.startsWith9', (value) => value.startsWith('9'))
  .matches(regex.nineDigits, 'errorMessages.nineDigits');
telephone.optional = telephone.base;

export default telephone;
