import mock from 'src/services/api/mock/mockAdapter';

import { mockDate } from './utilities';

const grants = {
  1: {
    id: 1,
    item: 'Renovación de maquinaria',
    amount: 40000,
    annotations: '',
    date: mockDate(),
    plots: [553, 0]
  }
};

mock.onGet('/api/grants').reply(200, {
  data: Object.keys(grants).map(key => grants[key]),
  page: 0,
  totalCount: Object.keys(grants).length
});

Object.keys(grants).forEach(key => {
  mock.onGet(`/api/grants/${grants[key].id}`).reply(200, {
    data: grants[key]
  });
});

mock.onGet(/\/api\/grants\?idClient=[0-9]*\/?/).reply(200, {
  data: Object.keys(grants).map(key => grants[key]),
  page: 0,
  totalCount: Object.keys(grants).length
});

mock.onGet(/\/api\/grants\?idPlot=[0-9]*\/?/).reply(200, {
  data: Object.keys(grants).map(key => grants[key]),
  page: 0,
  totalCount: Object.keys(grants).length
});
