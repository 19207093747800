import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField
} from '@material-ui/core';
import { useYupValidationResolver, Button } from 'bcp-material-core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Grid from 'src/components/Common/Grid';
import schema from 'src/components/Masters/KeyValueSearchForm/schema';

function KeyValueSearchForm({ onSearch, isLoading }) {
  const { t } = useTranslation();

  const { handleSubmit, reset, register, errors, isDisabled } = useForm({
    validationResolver: useYupValidationResolver(schema),
    defaultValues: { key: '', value: '' }
  });

  return (
    <form onSubmit={handleSubmit(onSearch)}>
      <Card>
        <CardHeader title={t('forms.titles.search')} />
        <Divider component="hr" />
        <CardContent component="div">
          <Grid container spacing={3}>
            <Grid item sm={12} xs={12}>
              <TextField
                type="text"
                name="value"
                label={t('labels.name')}
                inputRef={register}
                error={!!errors.value}
                disabled={isDisabled}
                autoComplete="on"
                helperText={t(errors?.value?.message) || ''}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider component="hr" />
        <CardActions>
          <Button onClick={reset} disabled={isLoading}>
            {t('actions.reset')}
          </Button>
          <Button type="submit" color="primary" isLoading={isLoading}>
            {t('actions.search')}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}

KeyValueSearchForm.defaultProps = {
  onSearch: null,
  isLoading: false
};

KeyValueSearchForm.propTypes = {
  onSearch: PropTypes.func,
  isLoading: PropTypes.bool
};

export default KeyValueSearchForm;
