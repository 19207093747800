import * as yup from 'yup';

import validations from 'src/utils/validations';

export default yup.object().shape({
  name: validations.plainText.optional,
  province: validations.selector.optional(),
  municipality: validations.selector.optional(),
  clientTypeId: validations.selector.optional()
});
