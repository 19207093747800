import Logger from 'src/utils/Logger';

const parseError = (errors, t) => {
  const customError = errors?.custom ? errors?.custom : false;
  const errorsList = [];

  if (customError && errors?.errors) {
    Object.keys(errors.errors).forEach(field => {
      errorsList.push(
        errors.errors[field].map(err => {
          const messageField =
            t(`forms.validations.${err.code}`) || err.message;

          if (field !== '__global') {
            const nameField = t(`labels.${field}`) || field;
            return `${nameField} : ${messageField}`;
          }
          return `${messageField}`;
        })
      );
    });
  } else if (errors?.detail) {
    Logger.error(errors.detail, 'ValidationErrorServer');
    errorsList.push(t(`forms.validations.ERROR_NOT_DEFINED`)+' ' +t(`common.message`)+': '+errors.detail);
  } else {
    Logger.error(JSON.stringify(errors), 'ValidationErrorServer');
    errorsList.push(t(`forms.validations.ERROR_NOT_DEFINED`));
  }

  return errorsList;
};

export default parseError;
