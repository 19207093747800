import * as yup from 'yup';

const positiveNumber = {
  base: yup
    .number()
    .typeError('errorMessages.number')
    .positive('errorMessages.integer')
};
positiveNumber.required = positiveNumber.base.required(
  'errorMessages.required'
);
positiveNumber.optional = yup.lazy(value =>
  value === '' ? yup.mixed() : positiveNumber.base
);

export default positiveNumber;
