import * as yup from 'yup';

import regex from 'src/utils/regex';

const numericString = {
  base: yup
    .string()
    .typeError('errorMessages.string')
    .matches(regex.numericString, {
      excludeEmptyString: true,
      message: 'errorMessages.numericString'
    })
};
numericString.required = numericString.base.required('errorMessages.required');
numericString.optional = numericString.base;

export default numericString;
