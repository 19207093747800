import * as yup from 'yup';

import validations from 'src/utils/validations';

export default yup.object().shape({
  // Client
  clientId: validations.plainText.required,
  clientTypeId: validations.selector.required(),
  cio: validations.plainText.optional,
  codeUGF: validations.plainText.optional,
  numberCertificateForest: validations.plainText.optional,
  area: validations.number.optional,
  name: validations.givenName.required,
  firstSurname: validations.familyName.optional,
  secondSurname: validations.familyName.optional,
  nif: validations.cifNifNie.optional,
  roadTypeId: validations.selector.optional(),
  address: validations.plainText.optional,
  number: validations.plainText.optional,
  apartmentBlock: validations.plainText.optional,
  floor: validations.plainText.optional,
  door: validations.plainText.optional,
  parish: validations.plainText.optional,
  location: validations.plainText.optional,
  postalCode: validations.plainText.optional,
  provinceId: validations.selector.optional(),
  municipalityId: validations.selector.optional(),
  place: validations.plainText.optional,
  telephone: validations.telephone.optional,
  fax: validations.fax.optional,
  mobile: validations.mobile.required,
  email: validations.email.optional,
  mountName: validations.plainText.optional,
  certificateForestDate: validations.materialUiDate.optional,

  // Representative
  representative: yup.object({
    name: validations.givenName.optional,
    firstSurname: validations.familyName.optional,
    secondSurname: validations.familyName.optional,
    nif: validations.cifNifNie.optional,
    representativeType: validations.selector.optional({ type: 'number' })
  }),

  // Reinvestment
  reinvestmentValidity: validations.materialUiDate.optional,
  lastReinvestmentCommunication: validations.materialUiDate.optional,

  // Other
  boardMeetingDate: validations.materialUiDate.optional,
  censusUpdateDate: validations.materialUiDate.optional
});
