import mapper from 'object-mapper';

const fromApiMap = {
  id: 'key',
  name: 'value',
  equivCORWEB: 'equivCORWEB',
  code: 'code'
};

const toApiMap = {
  key: 'id',
  value: 'name',
  equivCORWEB: 'equivCORWEB',  
  code: 'code'
};

export default {
  toApi: src => mapper(src, toApiMap),
  fromApi: src => mapper(src, fromApiMap),
  maps: {
    fromApiMap,
    toApiMap
  }
};
