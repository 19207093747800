import UserIcon from '@material-ui/icons/PermIdentityOutlined';
import StorageIcon from '@material-ui/icons/StorageOutlined';
import TimelineIcon from '@material-ui/icons/TimelineOutlined';
import MountIcon from '@material-ui/icons/Terrain';
import CalculateIcon from '@material-ui/icons/InsertChartOutlined';
import MessageIcon from '@material-ui/icons/Message';
import EcoIcon from '@material-ui/icons/Eco';
import { ROLES_ONLY_ADMIN, ROLES_ALL, ROLES_ADMIN_XESTOR } from 'src/config';
import urlConfig from 'src/utils/urlConfig';

export default t => [
  {
    subheader: t('menu.management'),
    items: [
      {
        title: t('menu.clients'),
        href: urlConfig.clients.list,
        icon: MountIcon,
        requiredAccessRole: ROLES_ALL
      },
      
    ]
  },{
    subheader: t('menu.tools'),
    items: [
      {
        title: t('menu.growthSimulator'),
        href: urlConfig.tools.growthSimulator,
        icon: CalculateIcon,
        requiredAccessRole: ROLES_ALL
      },      
      {
        title: t('menu.absorptionSimulator'),
        href: urlConfig.tools.absorptionSimulator,
        icon: EcoIcon,
        requiredAccessRole: ROLES_ALL
      },  
      {
        title: t('menu.resinerxiaTake'),
        href: urlConfig.tools.resinerxiaTake,
        icon: StorageIcon,
        requiredAccessRole: ROLES_ADMIN_XESTOR
      },  
      {
        title: t('menu.resinerxiaAnalysis'),
        href: urlConfig.tools.resinerxiaAnalysis,
        icon: TimelineIcon,
        requiredAccessRole: ROLES_ALL
      }
    ]
  },
  {
    subheader: t('menu.administration'),
    items: [
      {
        title: t('menu.users'),
        href: '/usuarios',
        icon: UserIcon,
        requiredAccessRole: ROLES_ONLY_ADMIN
      },
      {
        title: t('menu.masters'),
        href: '#',
        icon: StorageIcon,
        requiredAccessRole: ROLES_ALL,
        items: [
          {
            title: t('menu.basins'),
            href: urlConfig.masters.basins.list,
            requiredAccessRole: ROLES_ONLY_ADMIN
          },
          {
            title: t('menu.urbanClassifications'),
            href: urlConfig.masters.urbanClassifications.list,
            requiredAccessRole: ROLES_ONLY_ADMIN
          },
          {
            title: t('menu.buyers'),
            href: urlConfig.masters.buyers.list,
            requiredAccessRole: ROLES_ADMIN_XESTOR
          },
          {
            title: t('menu.municipalities'),
            href: urlConfig.masters.municipalities.list,
            requiredAccessRole: ROLES_ONLY_ADMIN
          },
          {
            title: t('menu.geographicalDemarcations'),
            href: urlConfig.masters.geographicalDemarcations.list,
            requiredAccessRole: ROLES_ONLY_ADMIN
          },
          {
            title: t('menu.species'),
            href: urlConfig.masters.species.list,
            requiredAccessRole: ROLES_ALL
          },
          {
            title: t('menu.udAmbientalGroups'),
            href: urlConfig.masters.udAmbientalGroups.list,
            requiredAccessRole: ROLES_ONLY_ADMIN
          },
          {
            title: t('menu.priorityHabitats'),
            href: urlConfig.masters.priorityHabitats.list,
            requiredAccessRole: ROLES_ONLY_ADMIN
          },
          {
            title: t('menu.lics'),
            href: urlConfig.masters.lics.list,
            requiredAccessRole: ROLES_ONLY_ADMIN
          },
          {
            title: t('menu.auctionMotives'),
            href: urlConfig.masters.auctionMotives.list,
            requiredAccessRole: ROLES_ONLY_ADMIN
          },
          {
            title: t('menu.provinces'),
            href: urlConfig.masters.provinces.list,
            requiredAccessRole: ROLES_ONLY_ADMIN
          },
          {
            title: t('menu.senlleiras'),
            href: urlConfig.masters.senlleiras.list,
            requiredAccessRole: ROLES_ONLY_ADMIN
          },
          {
            title: t('menu.situations'),
            href: urlConfig.masters.situations.list,
            requiredAccessRole: ROLES_ONLY_ADMIN
          },
          {
            title: t('menu.interventionSubtypes'),
            href: urlConfig.masters.interventionSubtypes.list,
            requiredAccessRole: ROLES_ONLY_ADMIN
          },
          {
            title: t('menu.interventionTypes'),
            href: urlConfig.masters.interventionTypes.list,
            requiredAccessRole: ROLES_ONLY_ADMIN
          },
          {
            title: t('menu.clientTypes'),
            href: urlConfig.masters.clientTypes.list,
            requiredAccessRole: ROLES_ONLY_ADMIN
          },
          {
            title: t('menu.auctionTypes'),
            href: urlConfig.masters.auctionTypes.list,
            requiredAccessRole: ROLES_ONLY_ADMIN
          },
          {
            title: t('menu.sigpacuses'),
            href: urlConfig.masters.sigpacuse.list,
            requiredAccessRole: ROLES_ONLY_ADMIN
          },
          {
            title: t('menu.roadTypes'),
            href: urlConfig.masters.roadTypes.list,
            requiredAccessRole: ROLES_ONLY_ADMIN
          }, {
            title: t('menu.zepas'),
            href: urlConfig.masters.zepas.list,
            requiredAccessRole: ROLES_ONLY_ADMIN
          }
        ]
      },
      {
        title: t('menu.logs'),
        href: urlConfig.logs.list,
        icon: MessageIcon,
        requiredAccessRole: ROLES_ONLY_ADMIN
      },
    ]
  }
];
