import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SelectControlled } from 'bcp-material-core';
import { makeStyles } from '@material-ui/core/styles';

import { getRolesForSelect } from 'src/config';

const styles = makeStyles(() => ({ menuPaper: { maxHeight: '50%' } }));

function RoleSelect({ control, ...selectControlledProps }) {
  const { t } = useTranslation();
  const classes = styles();

  return (
    <SelectControlled
      options={getRolesForSelect(t)}
      control={control}
      {...selectControlledProps}
      MenuProps={{ classes: { paper: classes.menuPaper } }}
    />
  );
}

RoleSelect.propTypes = {
  control: PropTypes.oneOfType([PropTypes.object]).isRequired
};

export default RoleSelect;
