import * as yup from 'yup';

import regex from 'src/utils/regex';

const url = {
  base: yup
    .string()
    .typeError('errorMessages.string')
    .matches(regex.url, {
      excludeEmptyString: true,
      message: 'errorMessages.url'
    })
};
url.required = url.base.required('errorMessages.required');
url.optional = url.base;

export default url;
