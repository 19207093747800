import * as yup from 'yup';

import validations from 'src/utils/validations';

export default yup.object().shape({
  userName: validations.username.required,
  name: validations.givenName.required,
  email: validations.email.required,
  firstSurname: validations.familyName.optional,
  secondSurname: validations.familyName.optional,
  role: validations.selector.required({ type: 'number' }),
  clientsIdentifiers: validations.selector.optional({ multiple: true }),
  active: yup.boolean()
});
