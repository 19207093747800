export default {
  nif: /([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])/,
  cif: /[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}/,
  nie: /[XxTtYyZz]{1}[0-9]{7}[a-zA-Z]{1}/,
  cifNifNie: /(([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9]))|([a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1})|([XxTtYyZz]{1}[0-9]{7}[a-zA-Z]{1})/,
  nineDigits: /^\d{9}$/,
  url: /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi,
  fax: /^(\+?\d+(\s?|-?)\d*(\s?|-?)\(?\d{2,}\)?(\s?|-?)\d{3,}\s?\d{3,})$/gm,
  numericString: /^\d+$/
};
