import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert/Alert';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAuth } from 'bcp-data';

import { LOGIN_PAGE } from 'src/config';
import useIsMounted from 'src/hooks/useIsMounted';
import parseError from 'src/utils/validations/helper';

function ErrorAlert({ error }) {
  const isMounted = useIsMounted();
  const alertRef = useRef();
  const [show, setShow] = useState(true);
  const { t } = useTranslation();
  const { logout } = useAuth();
  const history = useHistory();
  console.log(error.errors);
  //const { history } = useHistory();

  //If error code is 401 or 403 its needed to logout
  const isUnathorized = error?.errors?.__global?.some(
    error => error['code'] === '401' || error['code'] === '403'
  );

  if (isUnathorized) {
    logout();
    history.push(LOGIN_PAGE);
  }

  const errors = parseError(error, t);

  useEffect(() => {
    if (isMounted.current && alertRef.current) {
      // eslint-disable-next-line no-unused-expressions
      alertRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) setShow(true);
  }, [error]);

  // noinspection PointlessBooleanExpressionJS
  return show ? (
    <Alert
      ref={alertRef}
      severity="error"
      onClose={() => {
        if (isMounted.current) {
          setShow(false);
        }
      }}
    >
      {errors.length > 1 ? (
        <ul>
          {errors.map(err => {
            return <li>{err}</li>;
          })}
        </ul>
      ) : (
        errors.map(err => {
          return err;
        })
      )}
    </Alert>
  ) : (
    undefined
  );
}

ErrorAlert.propTypes = {
  // TODO: Cambiar por la forma acordada de los errores
  error: PropTypes.any.isRequired
};

export default React.memo(ErrorAlert);
