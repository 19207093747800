import * as yup from 'yup';

import Logger from 'src/utils/Logger';

const basicTypesValidation = {
  number: yup.number,
  string: yup.string,
  object: yup.object
};

const selector = {
  define: ({ type = 'string', multiple = false, specification } = {}) => {
    const typeValidator = specification
      ? basicTypesValidation[type](specification)
      : basicTypesValidation[type]();
    return multiple ? yup.array().of(typeValidator) : typeValidator;
  }
};
selector.required = ({
  type = 'string',
  multiple = false,
  specification
} = {}) =>
  selector
    .define({ type, multiple, specification })
    .required('errorMessages.required');
selector.optional = ({
  type = 'string',
  multiple = false,
  specification
} = {}) => {
  const baseValidation = selector.define({ type, multiple, specification });
  return yup.lazy(value => {
    switch (type) {
      case 'number':
        /** Assuming usage with bcp-material-core -> empty option is a string */
        return value === '' || (Array.isArray(value) && value.length === 0)
          ? yup.mixed()
          : baseValidation;
      case 'string':
        return baseValidation;
      default:
        Logger.warning(`
          There is no specific validation handler for select of type ${type}.
          This may yield unwanted validation results.
        `);
        return baseValidation;
    }
  });
};

export default selector;
