import { useHttpClient } from 'bcp-data';

export default Model => {
  const { find, get, post, put, del } = useHttpClient();

  const getModel = id => get(`${Model.endpoint}/${id}`, Model);

  const getModelAll = () => get(Model.endpoint, Model);

  const findModel = criteria => find(Model.endpoint, criteria, Model);

  const postModel = (data, headers) =>
    post(Model.endpoint, data, Model, headers);

  const putModel = (id, data, headers) =>
    put(`${Model.endpoint}/${id}`, data, Model, headers);

  const delModel = (id, headers) => del(`${Model.endpoint}/${id}`, headers);

  const getVolumenBasico = (specieId, feetNumber, Age, Zone, H01) => get(`${Model.endpoint}/volumenBasico/${specieId}/${feetNumber}/${Age}/${Zone}/${H01}`, Model);

  return {
    get: getModel,
    info: getModel,
    getAll: getModelAll,
    find: findModel,
    post: postModel,
    put: putModel,
    del: delModel,
    getVB: getVolumenBasico
  };
};
