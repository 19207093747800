import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField
} from '@material-ui/core';
import { useYupValidationResolver, Button } from 'bcp-material-core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Grid from 'src/components/Common/Grid';
import RemoteSelect from 'src/components/Common/LogicalSelects/RemoteSelect';
import schema from 'src/components/Masters/MunicipalitySearchForm/schema';
import Province from 'src/services/api/entities/masters/models/Province';

function MunicipalitySearchForm({ onSearch, isLoading }) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    reset,
    register,
    control,
    errors,
    isDisabled
  } = useForm({
    validationResolver: useYupValidationResolver(schema),
    defaultValues: { provinceId: '', value: '' }
  });

  return (
    <form onSubmit={handleSubmit(onSearch)} noValidate>
      <Card>
        <CardHeader title={t('forms.titles.search')} />
        <Divider component="hr" />
        <CardContent component="div">
          <Grid container spacing={3}>
            <Grid item sm={3} xs={12}>
              <RemoteSelect
                model={Province}
                name="provinceId"
                label={t('labels.province')}
                error={!!errors.provinceId}
                control={control}
                disabled={isDisabled}
                autoComplete="on"
                helperText={t(errors?.provinceId?.message) || ''}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                type="text"
                name="value"
                label={t('labels.name')}
                inputRef={register}
                error={!!errors.value}
                disabled={isDisabled}
                autoComplete="on"
                helperText={t(errors?.value?.message) || ''}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
          <TextField
            type="text"
            name="codCORWEB"
            label={t('labels.codCORWEB')}
            inputRef={register}
            error={!!errors.codCORWEB}
            disabled={isDisabled}
            required
            helperText={t(errors?.codCORWEB?.message) || ''}
          />
        </Grid>
          </Grid>
        </CardContent>
        <Divider component="hr" />
        <CardActions>
          <Button
            onClick={() => {
              reset({ provinceId: '', value: '' });
            }}
            disabled={isLoading}
          >
            {t('actions.reset')}
          </Button>
          <Button type="submit" color="primary" isLoading={isLoading}>
            {t('actions.search')}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}

MunicipalitySearchForm.defaultProps = {
  onSearch: null,
  isLoading: false
};

MunicipalitySearchForm.propTypes = {
  onSearch: PropTypes.func,
  isLoading: PropTypes.bool
};

export default MunicipalitySearchForm;
