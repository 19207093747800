// eslint-disable-next-line import/no-extraneous-dependencies
import AxiosMockAdapter from 'axios-mock-adapter';

import client from 'src/services/api/client';

const adapter = new AxiosMockAdapter(client, {
  delayResponse: 0,
  onNoMatch: 'passthrough'
});

export default adapter;
