import urlConfig from 'src/utils/urlConfig';

export const HOME_PAGE = urlConfig.clients.list;
export const LOGIN_PAGE = urlConfig.login;

// API.
export const API_URL = process.env.REACT_APP_API_URL;
export const API_TOKEN = 'YXJxdWV0aXBvLWNsaWVudDphcnF1ZXRpcG8tc2VjcmV0';
// export const API_TIMEOUT = 30000;

// List.
export const PAGE_SIZE = 20;
export const PAGE_SIZE_OPTIONS = [10, 20, 40, 60, 80, 100];

// Destination
export const DESTINATION = {
  SAW1: 0,
  SAW2: 1,
  TRITURATION: 2
};
export const getDestinationForSelect = t => [
  { key: DESTINATION.SAW1, value: t('destination.saw1') },
  { key: DESTINATION.SAW2, value: t('destination.saw2') },
  { key: DESTINATION.TRITURATION, value: t('destination.trituration') }
];

// Auction Calculation Type
export const AUCT_CALC_TYPE = {
  INDIVIDUAL: 0,
  CD: 1
};
export const getAuctCalcTypeForSelect = t => [
  { key: AUCT_CALC_TYPE.INDIVIDUAL, value: t('auctionCalType.individual') },
  { key: AUCT_CALC_TYPE.CD, value: t('auctionCalType.cd') }
];

// Roles.
export const ROLES = {
  ADMINISTRATOR: 0,
  MANAGER: 1,
  FOREST_OWNER: 2
};
export const getRolesForSelect = t => [
  { key: ROLES.ADMINISTRATOR, value: t('roles.Administrator') },
  { key: ROLES.MANAGER, value: t('roles.Manager') },
  { key: ROLES.FOREST_OWNER, value: t('roles.ForestOwner') }
];

// Roles para tablas
export const ROLES_FOR_CREATE = [ROLES.ADMINISTRATOR, ROLES.MANAGER];
export const ROLES_FOR_READ = [
  ROLES.ADMINISTRATOR,
  ROLES.MANAGER,
  ROLES.FOREST_OWNER
];
export const ROLES_FOR_EDIT = [ROLES.ADMINISTRATOR, ROLES.MANAGER];
export const ROLES_FOR_DELETE = [ROLES.ADMINISTRATOR, ROLES.MANAGER];

// Roles para menú lateral, ocultar opciones, etcétera
export const ROLES_ONLY_ADMIN = [ROLES.ADMINISTRATOR];
export const ROLES_ADMIN_XESTOR = [ROLES.ADMINISTRATOR, ROLES.MANAGER];
export const ROLES_ALL = [ROLES.ADMINISTRATOR, ROLES.MANAGER, ROLES.FOREST_OWNER];

export const PERMISSIONS = {
  ACCESS_MASTERS: 'ACCESS_MASTERS',
  ACCESS_USERS: 'ACCESS_USERS',
  ACCESS_CLIENTS: 'ACCESS_CLIENTS'
};

// Representative type
export const REPRESENTATIVE_TYPES = {
  MANAGER: 0,
  OTHERS: 1
};

export const getRepresentativeTypesForSelect = t => [
  { key: REPRESENTATIVE_TYPES.MANAGER, value: t('roles.Manager') },
  { key: REPRESENTATIVE_TYPES.OTHERS, value: t('roles.Other') }
];

// Holder Type
export const HOLDER_TYPES = {
  HOLDER: 0,
  PRESIDENT: 1,
  SECRETARY: 2,
  SPOKES_PERSON_1: 3,
  SPOKES_PERSON_2: 4,
  TREASURER: 5
};

export const getHolderTypesForSelect = t => [
  { key: HOLDER_TYPES.HOLDER, value: t('common.holder') },
  { key: HOLDER_TYPES.PRESIDENT, value: t('labels.president') },
  { key: HOLDER_TYPES.SECRETARY, value: t('labels.secretary') },
  {
    key: HOLDER_TYPES.SPOKES_PERSON_1,
    value: t('labels.spokesPerson', { which: 1 })
  },
  {
    key: HOLDER_TYPES.SPOKES_PERSON_2,
    value: t('labels.spokesPerson', { which: 2 })
  },
  {
    key: HOLDER_TYPES.TREASURER,
    value: t('labels.treasurer')
  }
];

// Document type.
export const DOCUMENT_TYPES = {
  CLIENT: 0,
  INVENTORY_FILE: 1,
  BALANCE: 2
};

// Balance Type.
export const BALANCE_TYPES = {
  FACTURA_EMITIDA: 0,
  FACTURA_RECIBIDA: 1,
  PROFORMA: 2,
  EXTRACTO_BANCARIO: 3,
  BALANCE_SITUACION: 4,
  PERDIDAS_GANANCIAS: 5
};
export const getBalanceTypesForSelect = () => [
  { key: BALANCE_TYPES.FACTURA_EMITIDA, value: 'Factura emitida' },
  { key: BALANCE_TYPES.FACTURA_RECIBIDA, value: 'Factura recibida' },
  { key: BALANCE_TYPES.PROFORMA, value: 'Proforma' },
  { key: BALANCE_TYPES.EXTRACTO_BANCARIO, value: 'Extracto bancario' },
  { key: BALANCE_TYPES.BALANCE_SITUACION, value: 'Balance situación' },
  { key: BALANCE_TYPES.PERDIDAS_GANANCIAS, value: 'Pérdidas y ganancias' }
];

// Images.
const publicPath = '/';
const imagesPath = `${publicPath}images/`;
export const AVATAR_DEFAULT = `${imagesPath}avatar_default.png`;
export const LOGIN_BACKGROUND = `${imagesPath}login.jpg`;
export const LOGO = `${imagesPath}/logos/logo.png`;

// URLs.
export const REINVESTMENT_URL =
  'https://sede.xunta.gal/detalle-procedemento?langId=es_ES&codtram=MR652A';

// REFERENCE CONSTANTS
// export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

// Promise Status
export const PROMISE_STATUS = {
  REJECTED: 'rejected',
  FULFILLED: 'fulfilled'
};

// Inventory Items.
export const INVENTORY_ITEM_TYPES = {
  ORDINATION_PROJECT: 0,
  EXPLOITATION: 1,
  INVESTIGATION: 2
};
export const INVENTORY_ITEM_TYPE_LABELS = {
  [INVENTORY_ITEM_TYPES.ORDINATION_PROJECT]: 'labels.ordinanceProject',
  [INVENTORY_ITEM_TYPES.EXPLOITATION]: 'labels.exploitationProject',
  [INVENTORY_ITEM_TYPES.INVESTIGATION]: 'labels.investigationProject'
};

// Map.
export const SHAPE_TYPES = {
  POINT: 'POINT',
  POLYGON: 'POLYGON'
};
