import PropTypes from 'prop-types';
import React from 'react';
import { FormSectionTitle } from 'bcp-material-core';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Grid from 'src/components/Common/Grid';
import RemoteSelect from 'src/components/Common/LogicalSelects/RemoteSelect';
import Province from 'src/services/api/entities/masters/models/Province';

function MunicipalityForm({
  id,
  handleSubmit,
  register,
  control,
  errors,
  isDisabled,
}) {
  const { t } = useTranslation();

  return (
    <form id={id} onSubmit={handleSubmit} noValidate>
      <FormSectionTitle
        title={t('forms.titles.dataOf', { what: t('common.municipality') })}
      />
      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <RemoteSelect
            model={Province}
            name="provinceId"
            label={t('labels.province')}
            error={!!errors.provinceId}
            disabled={isDisabled}
            control={control}
            autoComplete="on"
            required
            helperText={t(errors?.provinceId?.message) || ''}
          />
        </Grid>
        <Grid item sm={5} xs={12}>
          <TextField
            type="text"
            name="value"
            label={t('labels.name')}
            inputRef={register}
            error={!!errors.value}
            disabled={isDisabled}
            autoComplete="on"
            required
            helperText={t(errors?.value?.message) || ''}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            type="text"
            name="codCORWEB"
            label={t('labels.codCORWEB')}
            inputRef={register}
            error={!!errors.codCORWEB}
            disabled={isDisabled}
            required
            helperText={t(errors?.codCORWEB?.message) || ''}
          />
        </Grid>
      </Grid>
    </form>
  );
}

MunicipalityForm.defaultProps = {
  id: 'municipality-form',
  isDisabled: false,
};

MunicipalityForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabled: PropTypes.bool,
};

export default MunicipalityForm;
