import { mockDate } from './utilities';
import mock from './mockAdapter';

const balance = {
  1: {
    id: 1,
    type: 'Subasta',
    concept: 'Madera',
    annotations: 'Se han vendido 15 tons',
    amount: 100000,
    attachment: 'adjunto1',
    date: mockDate()
  },
  2: {
    id: 2,
    type: 'Poda',
    concept: 'Parcela 1007',
    annotations: 'Se han podado 10 Ha',
    amount: -2000,
    attachment: 'adjunto2',
    date: mockDate()
  },
  3: {
    id: 3,
    type: 'Adquisición',
    concept: 'Maquinaria',
    annotations: 'Podadoras',
    amount: -500,
    attachment: 'adjunto3',
    date: mockDate()
  }
};

mock.onGet('/api/balance').reply(200, {
  data: Object.keys(balance).map(key => balance[key]),
  page: 0,
  totalCount: Object.keys(balance).length
});

Object.keys(balance).forEach(key => {
  mock.onGet(`/api/balance/${balance[key].id}`).reply(200, {
    data: balance[key]
  });
});

mock.onGet(/\/api\/balance\?idClient=[0-9]*\/?/).reply(200, {
  data: Object.keys(balance).map(key => balance[key]),
  page: 0,
  totalCount: Object.keys(balance).length
});
