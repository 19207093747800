import mock from 'src/services/api/mock/mockAdapter';

import { mockDate } from './utilities';

const auctions = {
  1: {
    id: 1,
    item: 'Madeira',
    quantity: 100,
    unit: 'm3',
    price: '15',
    date: mockDate(),
    plots: [
      '1c6563af-6195-4292-cec0-08d800c42ab3',
      '10bb4e07-e57a-411c-cebd-08d800c42ab3'
    ]
  },
  2: {
    id: 2,
    item: 'Auga',
    quantity: 20,
    unit: 'm3',
    price: '3',
    date: mockDate(),
    plots: [
      '9390c263-efde-40f5-cebf-08d800c42ab3',
      'b0c1f199-25aa-4bd9-cebc-08d800c42ab3',
      '4ab17852-c8fe-4ccc-cebe-08d800c42ab3'
    ]
  },
  3: {
    id: 3,
    item: 'Madeira',
    quantity: 5000,
    unit: 'm3',
    price: '1000',
    date: mockDate(),
    plots: [
      'b0c1f199-25aa-4bd9-cebc-08d800c42ab3',
      '10bb4e07-e57a-411c-cebd-08d800c42ab3'
    ]
  }
};

mock.onGet('/api/auctions').reply(200, {
  data: Object.keys(auctions).map(key => auctions[key]),
  page: 0,
  totalCount: Object.keys(auctions).length
});

Object.keys(auctions).forEach(key => {
  mock.onGet(`/api/auctions/${auctions[key].id}`).reply(200, {
    data: auctions[key]
  });
});

mock.onGet(/\/api\/auctions\?idClient=[0-9]*\/?/).reply(200, {
  data: Object.keys(auctions).map(key => auctions[key]),
  page: 0,
  totalCount: Object.keys(auctions).length
});

mock.onGet(/\/api\/auctions\?idPlot=[0-9]*\/?/).reply(200, {
  data: Object.keys(auctions).map(key => auctions[key]),
  page: 0,
  totalCount: Object.keys(auctions).length
});
