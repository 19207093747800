import mock from 'src/services/api/mock/mockAdapter';

const masters = {
  habitats: {
    id: 1,
    name: 'Hábitats prioritarios'
  },
  roads: {
    id: 2,
    name: 'Carreteiras'
  },
  rivers: {
    id: 3,
    name: 'Ríos'
  },
  patrimonials: {
    id: 4,
    name: 'Elementos patrimoniais'
  },
  strains: {
    id: 5,
    name: 'Cepas'
  },
  units: {
    id: 6,
    name: 'Unidades'
  }
};

mock.onGet('/api/masters').reply(200, {
  data: Object.keys(masters).map(key => masters[key]),
  page: 0,
  totalCount: Object.keys(masters).length
});

Object.keys(masters).forEach(key => {
  mock.onGet(`/api/${key}`).reply(200, {
    data: masters[key]
  });
});
