import { KeyValue } from 'bcp-data';

import KeyValueSearchForm from 'src/components/Masters/KeyValueSearchForm';
import searchSchema from 'src/components/Masters/KeyValueSearchForm/schema';
import ProvinceForm from 'src/components/Masters/ProvinceForm';
import schema from 'src/components/Masters/ProvinceForm/schema';
import useModel from 'src/services/api/hooks/useModel';
import mapper from 'src/services/api/entities/masters/mappers/provincesMapper';

export default class Province extends KeyValue {
  static endpoint = 'provinces';

  static apiHook = () => useModel(Province);

  static mapper = mapper;

  codCORWEB;


  static form = {
    component: ProvinceForm,
    schema
  };

  static searchForm = {
    component: KeyValueSearchForm,
    schema: searchSchema
  };

  constructor(source, id) {
    super(source);
    this.key = id ?? this.key;
    this.codCORWEB = source.codCORWEB;
  }

  static fromApi(apiModel) {
    return new Province(mapper.fromApi(apiModel));
  }

  static toApi(clientModel) {
    return mapper.toApi(clientModel);
  }

  static empty() {
    return { key: '', value: '' ,   codCORWEB: 0};
  }
}
