import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Fetch from 'i18next-fetch-backend';

export const defaultLocale = 'gl';

i18n
  .use(Fetch)
  .use(initReactI18next)
  .init({
    lng: defaultLocale,
    fallbackLng: 'es',
    ns: ['translation', 'mobile'],
    defaultNS: 'translation',
    debug: process.env.NODE_ENV !== 'production',
    parseMissingKeyHandler: () => {
      return '';
    },
    interpolation: {
      escapeValue: false,
      format: (value, format) => {
        if (format === 'lowercase' && typeof value === 'string')
          return value.toLowerCase();
        return value;
      }
    }
  });

export default i18n;
