import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';

import { AVATAR_DEFAULT } from 'src/config';
import User from 'src/services/api/entities/User/model/User';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 48,
    padding: theme.spacing(1),
  },
  avatar: {
    width: 36,
    height: 36,
    borderRadius: '50%',
    overflow: 'hidden',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain'
    }
  },
  name: {
    maxWidth: 180,
    paddingLeft: theme.spacing(1),
    color: 'inherit',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

function UserSnack({ user, className, classes }) {
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.avatar}>
        <img alt="User avatar" src={user?.avatar || AVATAR_DEFAULT} />
      </div>
      <Typography
        variant="button"
        className={classes.name}
        title={`${user.name} ${user?.firstSurname}`}
      >
        {`${user.name} ${user?.firstSurname ?? ''}`}
      </Typography>
    </div>
  );
}

UserSnack.defaultProps = {
  className: '',
  classes: {}
};

UserSnack.propTypes = {
  user: PropTypes.instanceOf(User).isRequired,
  className: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    avatar: PropTypes.string,
    name: PropTypes.string
  })
};

export default withStyles(styles, { name: 'AmetlamUserSnack' })(UserSnack);
