import * as yup from 'yup';

import number from 'src/utils/validations/number';

const date = {
  base: yup
    .object()
    .shape({
      $ms: number.optional,
      $s: number.optional,
      $m: number.optional,
      $H: number.optional,
      $D: number.optional,
      $W: number.optional,
      $M: number.optional,
      $y: number.optional,
      $u: number.optional,
      $L: yup.string().typeError('errorMessages.string'),
      $d: yup.mixed()
    })
    .nullable()
};
date.required = date.base.required('errorMessages.required');
date.optional = date.base.default(null);

export default date;
