import mock from 'src/services/api/mock/mockAdapter';

import { mockDate } from './utilities';

const users = {
  1: {
    id: 1,
    created: mockDate(),
    username: 'pacaf',
    password: '',
    name: 'Pablo',
    surname: 'Casal Flores',
    active: true,
    role: 'Administrador',
    clients: []
  },
  2: {
    id: 2,
    created: mockDate(),
    username: 'robise',
    password: '',
    name: 'Roberto',
    surname: 'Iglesias Seoane',
    active: true,
    role: 'Xestor',
    clients: [1, 2]
  },
  3: {
    id: 3,
    created: mockDate(),
    username: 'terca',
    password: '',
    name: 'Teresa',
    surname: 'Caínzos Bravo',
    active: true,
    role: 'Comuneiro',
    clients: [1]
  },
  4: {
    id: 4,
    created: mockDate(),
    username: 'magop',
    password: '',
    name: 'Manuel',
    surname: 'González Pérez',
    active: false,
    role: 'Comuneiro',
    clients: [2, 3, 4]
  },
  5: {
    id: 5,
    created: mockDate(),
    username: 'sapo',
    password: '',
    name: 'Sara',
    surname: 'Pol Alonso',
    active: true,
    role: 'Comuneiro',
    clients: [1, 4, 5]
  },
  6: {
    id: 6,
    created: mockDate(),
    username: 'macalo',
    password: '',
    name: 'María',
    surname: 'Castro López',
    active: false,
    role: 'Xestor',
    clients: [4, 5, 6]
  },
  7: {
    id: 7,
    created: mockDate(),
    username: 'tereto',
    password: '',
    name: 'Teresa',
    surname: 'Revilla Torres',
    active: false,
    role: 'Comuneiro',
    clients: [3]
  },
  8: {
    id: 8,
    created: mockDate(),
    username: 'acape',
    password: 'asd',
    name: 'Ana',
    surname: 'Carballada Pérez',
    active: true,
    role: 'Comuneiro',
    clients: [1, 2, 3, 4, 5, 6]
  }
};

mock.onGet('/api/users').reply(200, {
  data: Object.keys(users).map(key => users[key]),
  page: 0,
  totalCount: Object.keys(users).length
});

Object.keys(users).forEach(key => {
  mock.onGet(`/api/users/${users[key].id}`).reply(200, {
    data: users[key]
  });
});
