import * as yup from 'yup';

import date from 'src/utils/validations/date';
import timestamp from 'src/utils/validations/timestamp';

const materialUiDate = {
  base: yup.lazy(value =>
    typeof value === 'object' ? date.base : timestamp.base
  ),
  required: yup.lazy(value =>
    typeof value === 'object' ? date.required : timestamp.required
  ),
  optional: yup.lazy(value => {
    if (value === null) {
      return yup.mixed();
    }
    return typeof value === 'object' ? date.optional : timestamp.optional;
  })
};

export default materialUiDate;
