import PropTypes from 'prop-types';
import React from 'react';
import { FormSectionTitle } from 'bcp-material-core';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Grid from 'src/components/Common/Grid';

function ClientTypeForm({ id, handleSubmit, register, errors, isDisabled }) {
  const { t } = useTranslation();

  return (
    <form id={id} onSubmit={handleSubmit} noValidate>
      <FormSectionTitle
        title={t('forms.titles.dataOf', {
          what: t('labels.typeOf_plural', { what: t('common.client') })
        })}
      />
      <Grid container spacing={3}>
        <Grid item sm={12} xs={12}>
          <TextField
            type="text"
            name="value"
            label={t('labels.name')}
            inputRef={register}
            error={!!errors.value}
            disabled={isDisabled}
            autoComplete="on"
            required
            helperText={t(errors?.value?.message) || ''}
          />
        </Grid>
      </Grid>
    </form>
  );
}

ClientTypeForm.defaultProps = {
  id: 'client-type-form',
  isDisabled: false
};

ClientTypeForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabled: PropTypes.bool
};

export default ClientTypeForm;
