import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  TextField
} from '@material-ui/core';
import { Criteria } from 'bcp-data';
import { useYupValidationResolver, Button } from 'bcp-material-core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Grid from 'src/components/Common/Grid';
import schema from 'src/components/Client/ClientSearchForm/schema';
import RemoteSelect from 'src/components/Common/LogicalSelects/RemoteSelect';
import ClientType from 'src/services/api/entities/masters/models/ClientType';
import Municipality from 'src/services/api/entities/masters/models/Municipality';
import Province from 'src/services/api/entities/masters/models/Province';

function ClientSearchForm({ onSearch, isLoading, defaultValue }) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    errors,
    isDisabled
  } = useForm({
    validationResolver: useYupValidationResolver(schema),
    defaultValues: defaultValue
  });

  const selectedProvince = watch('provinceId');

  return (
    <form onSubmit={handleSubmit(onSearch)}>
      <Card>
        <CardHeader title={t('forms.titles.search')} />
        <Divider component="hr" />
        <CardContent component="div">
          <Grid container spacing={3}>
            <Grid item md={12} sm={6} xs={12}>
              <TextField
                type="text"
                name="name"
                label={t('labels.name')}
                inputRef={register}
                error={!!errors.name}
                disabled={isDisabled}
                autoComplete="on"
                helperText={t(errors?.name?.message) || ''}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <RemoteSelect
                model={Province}
                name="provinceId"
                label={t('labels.province')}
                error={!!errors.provinceId}
                disabled={isDisabled}
                control={control}
                autoComplete="on"
                helperText={t(errors?.provinceId?.message) || ''}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <RemoteSelect
                model={Municipality}
                name="municipalityId"
                label={t('labels.municipality')}
                error={!!errors.municipalityId}
                disabled={isDisabled || !selectedProvince}
                control={control}
                criteria={new Criteria({ provinceId: selectedProvince })}
                defaultValue=""
                hasDependency
                autoComplete="on"
                helperText={t(errors?.municipalityId?.message) || ''}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <RemoteSelect
                model={ClientType}
                name="clientTypeId"
                label={t('labels.type')}
                error={!!errors.clientTypeId}
                disabled={isDisabled}
                control={control}
                autoComplete="on"
                helperText={t(errors?.clientTypeId?.message) || ''}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <></>
            </Grid>
          </Grid>
        </CardContent>
        <Divider component="hr" />
        <CardActions>
          <Button onClick={() => reset(defaultValue)} disabled={isLoading}>
            {t('actions.reset')}
          </Button>
          <Button type="submit" color="primary" isLoading={isLoading}>
            {t('actions.search')}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}

ClientSearchForm.propTypes = {
  onSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  defaultValue: PropTypes.any
};

ClientSearchForm.defaultProps = {
  onSearch: null,
  isLoading: false,
  defaultValue: ''
};

export default ClientSearchForm;
