import * as yup from 'yup';
import regex from 'src/utils/regex';

const cif = {
  base: yup
    .string()
    .typeError('errorMessages.string')
    .matches(regex.cif, {
      excludeEmptyString: true,
      message: 'errorMessages.cif'
    })
};

cif.required = cif.base.required('errorMessages.required');
cif.optional = cif.base;

const cifNifNie = {
  base: yup
    .string()
    .typeError('errorMessages.string')
    .matches(regex.cifNifNie, {
      excludeEmptyString: true,
      message: 'errorMessages.cifNifNie'
    })
};

cifNifNie.required = cifNifNie.base.required('errorMessages.required');
cifNifNie.optional = cifNifNie.base;

const nif = {
  base: yup
    .string()
    .typeError('errorMessages.string')
    .matches(regex.nif, {
      excludeEmptyString: true,
      message: 'errorMessages.nif'
    })
};

nif.required = nif.base.required('errorMessages.required');
nif.optional = nif.base;

const nie = {
  base: yup
    .string()
    .typeError('errorMessages.string')
    .matches(regex.nif, {
      excludeEmptyString: true,
      message: 'errorMessages.nie'
    })
};

nie.required = nie.base.required('errorMessages.required');
nie.optional = nie.base;

export { cif, nif, nie, cifNifNie };
