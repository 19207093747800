import currency from 'src/utils/validations/currency';
import email from 'src/utils/validations/email';
import familyName from 'src/utils/validations/familyName';
import materialUiDate from 'src/utils/validations/materialUiDate';
import mobile from 'src/utils/validations/mobile';
import { nif, cif, nie, cifNifNie } from 'src/utils/validations/identifiers';
import givenName from 'src/utils/validations/givenName';
import date from 'src/utils/validations/date';
import password from 'src/utils/validations/password';
import plainText from 'src/utils/validations/plainText';
import positiveInteger from 'src/utils/validations/positiveInteger';
import selector from 'src/utils/validations/selector';
import timestamp from 'src/utils/validations/timestamp';
import username from 'src/utils/validations/username';
import unit from 'src/utils/validations/unit';
import url from 'src/utils/validations/url';
import telephone from 'src/utils/validations/telephone';
import fax from 'src/utils/validations/fax';
import number from 'src/utils/validations/number';
import positiveNumber from 'src/utils/validations/positiveNumber';
import booleanSelector from 'src/utils/validations/booleanSelector';
import numericString from 'src/utils/validations/numericString';
import radioButton from 'src/utils/validations/radioButton';

export default {
  nif,
  cif,
  nie,
  cifNifNie,
  givenName,
  familyName,
  mobile,
  email,
  date,
  timestamp,
  materialUiDate,
  plainText,
  selector,
  positiveInteger,
  currency,
  unit,
  username,
  password,
  url,
  telephone,
  fax,
  number,
  positiveNumber,
  booleanSelector,
  numericString,
  radioButton
};
