import React, { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import routes from 'src/routes';
import 'src/services/api/mock';
import AppProviders from 'src/components/Common/App/AppProviders';

const history = createBrowserHistory();

if (process.env.NODE_ENV !== 'production') {
  localStorage.setItem('debug', 'bcp-react-app:*');
}

function App() {
  return (
    <AppProviders>
      <Suspense fallback={<></>}>
        <Router history={history}>{renderRoutes(routes)}</Router>
      </Suspense>
    </AppProviders>
  );
}

export default App;
