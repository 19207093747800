import { httpClient } from 'bcp-data';
import { stringify } from 'qs';

import { API_TOKEN, API_URL } from 'src/config';

export const apiConfig = {
  baseUrl: API_URL,
  paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
  token: API_TOKEN,
  attributeCase: 'camel'
};
const instance = httpClient(apiConfig);

export default instance;
