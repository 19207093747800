import { BaseUser } from 'bcp-data';

import UserSearchForm from 'src/components/User/UserSearchForm';
import UserForm from 'src/components/User/UserForm';
import creationSchema from 'src/components/User/UserForm/creationSchema';
import updateSchema from 'src/components/User/UserForm/updateSchema';
import searchSchema from 'src/components/User/UserSearchForm/schema';
import useModel from 'src/services/api/hooks/useModel';
import mapper from 'src/services/api/entities/User/mapper/userMapper';

export default class User extends BaseUser {
  static endpoint = 'users';

  static apiHook = () => useModel(User);

  static mapper = mapper;

  static form = {
    component: UserForm,
    schema: isUpdate => (isUpdate ? updateSchema : creationSchema)
  };

  static searchForm = {
    component: UserSearchForm,
    schema: searchSchema
  };

  id;

  userName;

  password;

  email;

  name;

  firstSurname;

  secondSurname;

  roleName;

  role;

  active;

  clients;

  clientsIdentifiers;

  constructor(user, id = null) {
    super(user);
    this.id = id ?? this.id;
  }

  static fromApi(apiModel) {
    return new User(mapper.fromApi(apiModel));
  }

  static toApi(clientModel) {
    return mapper.toApi(clientModel);
  }

  static empty() {
    return {
      id: '',
      userName: '',
      password: '',
      email: '',
      name: '',
      firstSurname: '',
      secondSurname: '',
      role: '',
      roleName: '',
      active: true,
      clients: [],
      clientsIdentifiers: []
    };
  }

  denullify(transformer) {
    return super.denullify({
      nonNullableFields: ['id'],
      ...transformer
    });
  }
}
