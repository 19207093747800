import mock from 'src/services/api/mock/mockAdapter';

const clients = {
  1: {
    id: 1,
    name: 'POF del monte de arriba',
    area: '1168 Ha',
    province: 'Ourense',
    municipality: 'A Veiga',
    ownership: 'CMVMC',
    parish: 'Vilamaior',
    place: 'Chaos',
    representative: 1,
    cio: 'PO00011253Y',
    reinvestment: 4,
    owners: [1, 2, 3, 4],
    boards: [1, 2],
    balance: [1, 2, 3],
    auctions: [1, 2, 3],
    interventions: [1, 2, 3],
    grants: [1, 2, 3],
    map: 1
  },
  2: {
    id: 2,
    name: 'POF del monte de abajo',
    area: '1375 Ha',
    province: 'Ourense',
    municipality: 'Coles',
    ownership: 'SOFOR',
    parish: 'Vilamaior',
    place: 'Chaos',
    representative: 1,
    cio: 'PO00011253Y',
    reinvestment: 4,
    owners: [1, 2, 3, 4],
    boards: [1, 2],
    balance: [1, 2, 3],
    auctions: [1, 2, 3],
    interventions: [1, 2, 3],
    grants: [1, 2, 3],
    map: 1
  },
  3: {
    id: 3,
    name: 'MVMC do Serra',
    area: '2015 Ha',
    province: 'Ourense',
    municipality: 'San Cibrao',
    ownership: 'CMVMC',
    parish: 'Vilamaior',
    place: 'Chaos',
    representative: 1,
    cio: 'PO00011253Y',
    reinvestment: 4,
    owners: [1, 2, 3, 4],
    boards: [1, 2],
    balance: [1, 2, 3],
    auctions: [1, 2, 3],
    interventions: [1, 2, 3],
    grants: [1, 2, 3],
    map: 1
  },
  4: {
    id: 4,
    name: 'Asociación de veciños do Sixto',
    area: '2668 Ha',
    province: 'Lugo',
    municipality: 'Monforte',
    parish: 'Vilamaior',
    place: 'Chaos',
    ownership: 'Asociación',
    representative: 1,
    cio: 'PO00011253Y',
    reinvestment: 4,
    owners: [1, 2, 3, 4],
    boards: [1, 2],
    balance: [1, 2, 3],
    auctions: [1, 2, 3],
    interventions: [1, 2, 3],
    grants: [1, 2, 3],
    map: 1
  },
  5: {
    id: 5,
    name: 'Familia López Pérez',
    area: '1168 Ha',
    province: 'Ourense',
    municipality: 'Celanova',
    ownership: 'Particular',
    parish: 'Vilamaior',
    place: 'Chaos',
    representative: 1,
    cio: 'PO00011253Y',
    reinvestment: 4,
    owners: [1, 2, 3, 4],
    boards: [1, 2],
    balance: [1, 2, 3],
    auctions: [1, 2, 3],
    interventions: [1, 2, 3],
    grants: [1, 2, 3],
    map: 1
  },
  6: {
    id: 6,
    name: 'Esto está inventado',
    area: '1168 Ha',
    province: 'A Coruña',
    municipality: 'Sada',
    ownership: 'Particular',
    parish: 'Nonsei',
    place: 'Algures',
    representative: 1,
    cio: 'PO00011253Y',
    reinvestment: 55,
    owners: [1, 2, 3, 4],
    boards: [1, 2],
    balance: [1, 2, 3],
    auctions: [1, 2, 3],
    interventions: [1, 2, 3],
    grants: [1, 2, 3],
    map: 1
  }
};

mock.onGet('/api/clients').reply(200, {
  data: Object.keys(clients).map(clientKey => clients[clientKey]),
  page: 0,
  totalCount: Object.keys(clients).length
});

Object.keys(clients).forEach(clientKey => {
  mock.onGet(`/api/clients/${clients[clientKey].id}`).reply(200, {
    data: clients[clientKey]
  });
});
