import { createMuiTheme } from '@material-ui/core';

import { defaultLocale } from 'src/i18n';
import Logger from 'src/utils/Logger';

import localLocales from './locale';
import palette from './palette';
import overrides from './overrides';
import typography from './typography';

const fallbackLocale = 'gl';
const localeMap = {
  es: {
    code: 'esES',
    external: true
  },
  gl: {
    code: 'glES',
    external: false
  }
};

const baseTheme = {
  palette,
  typography,
  props: {
    MuiButton: {
      variant: 'contained'
    },
    MuiContainer: {
      maxWidth: 'xl'
    },
    MuiTextField: {
      variant: 'outlined',
      fullWidth: true
    },
    MuiFilledInput: {
      variant: 'outlined'
    },
    MuiFormControl: {
      fullWidth: true,
      variant: 'outlined'
    }
  }
};

export default async (locale = defaultLocale) => {
  let localeToUse = null;
  if (Object.keys(localeMap).includes(locale)) {
    localeToUse = localeMap[locale];
  } else {
    Logger.warning(
      `The current locale (${locale}) is not supported by MUI, 
      using fallback locale (${fallbackLocale})`
    );
    localeToUse = localeMap[fallbackLocale];
  }

  let localeData = null;
  // Externally loaded locale
  if (localeToUse.external) {
    const locales = await import('@material-ui/core/locale');
    localeData = locales[localeToUse.code];
  }
  // Locally loaded locale
  else {
    localeData = localLocales[localeToUse.code]();
  }
  const theme = createMuiTheme(baseTheme, localeData);
  theme.overrides = overrides(theme);
  return theme;
};
