import { BaseModel } from 'bcp-data';

import ClientForm from 'src/components/Client/ClientForm';
import schema from 'src/components/Client/ClientForm/schema';
import searchSchema from 'src/components/Client/ClientSearchForm/schema';
import ClientSearchForm from 'src/components/Client/ClientSearchForm';
import useModel from 'src/services/api/hooks/useModel';
import mapper from 'src/services/api/entities/Client/mapper/clientMapper';

export default class Client extends BaseModel {
  static endpoint = 'clients';

  static apiHook = () => useModel(Client);

  static mapper = mapper;

  static form = {
    component: ClientForm,
    schema
  };

  static searchForm = {
    component: ClientSearchForm,
    schema: searchSchema
  };

  id;

  clientId;

  clientTypeName;

  clientTypeId;

  hasCio;

  cio;

  codeUGF;

  numberCertificateForest;

  name;

  firstSurname;

  secondSurname;

  nif;

  address;

  provinceId;

  provinceName;

  province;

  municipalityId;

  municipalityName;

  municipality;

  parish;

  place;

  number;

  apartmentBlock;

  floor;

  door;

  postalCode;

  location;

  roadTypeId;

  telephone;

  fax;

  mobile;

  email;

  area;

  reinvestmentValidity;

  lastReinvestmentCommunication;

  pathArgis;

  boardMeetingDate;

  censusUpdateDate;

  mountName;

  certificateForestDate;

  corwebUser;

  corwebPassword;

  corwebRenewedDate;

  corwebReadPassword;

  /**
   * {
   *   name;
   *   surname;
   *   nif;
   *   representativeType;
   * }
   */
  representative;

  constructor(client, id = null) {
    super(client);
    this.id = id ?? this.id;
    this.hasCio = !!this.cio;
  }

  static fromApi(apiModel) {
    return new Client(mapper.fromApi(apiModel));
  }

  static toApi(clientModel) {
    return mapper.toApi(clientModel);
  }

  static toKeyValue(clientModel) {
    return {
      key: clientModel.id,
      value: clientModel.name
    };
  }

  static empty() {
    return {
      id: '',
      clientId: '',
      clientTypeName: '',
      clientTypeId: '',
      hasCio: false,
      cio: '',
      codeUGF: '',
      numberCertificateForest: '',
      name: '',
      firstSurname: '',
      secondSurname: '',
      nif: '',
      address: '',
      provinceId: '',
      provinceName: '',
      province: '',
      municipalityId: '',
      municipalityName: '',
      municipality: '',
      parish: '',
      place: '',
      number: '',
      apartmentBlock: '',
      floor: '',
      door: '',
      postalCode: '',
      location: '',
      roadTypeId: '',
      telephone: '',
      fax: '',
      mobile: '',
      email: '',
      area: '',
      reinvestmentValidity: Date.now(),
      lastReinvestmentCommunication: Date.now(),
      pathArgis: '',
      boardMeetingDate: Date.now(),
      censusUpdateDate: Date.now(),
      mountName: '',
      certificateForestDate: Date.now(),
      corwebUser: '',
      corwebPassword: '',
      corwebReadPassword: '',
      representative: {
        name: '',
        surname: '',
        nif: '',
        representativeType: ''
      },      
      corwebRenewedDate: ''
    };

  }

  denullify(transformer) {
    return super.denullify({
      nonNullableFields: ['id'],
      ...transformer
    });
  }
}
