import {
  ROLES_FOR_CREATE,
  ROLES_FOR_DELETE,
  ROLES_FOR_EDIT,
  ROLES_FOR_READ
} from 'src/config';
import useRole from 'src/hooks/useRole';

function useAccessLevel() {
  const { hasRole } = useRole();

  const accessLevel = {
    create: hasRole(ROLES_FOR_CREATE),
    read: hasRole(ROLES_FOR_READ),
    update: hasRole(ROLES_FOR_EDIT),
    delete: hasRole(ROLES_FOR_DELETE)
  };

  return {
    accessLevel,
    hasRole
  };
}

export default useAccessLevel;
