import * as yup from 'yup';

import regex from 'src/utils/regex';

const mobile = {
  base: yup
    .string()
    .typeError('errorMessages.string')
};
mobile.required = mobile.base
  .required('errorMessages.required')
  .matches(regex.nineDigits, 'errorMessages.nineDigits')
  .test('starts-with-6', 'errorMessages.startsWith6', (value) => value.startsWith('6'));
mobile.optional = mobile.base;

export default mobile;
