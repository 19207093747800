import React from 'react';
import PropTypes from 'prop-types';
import MuiGrid from '@material-ui/core/Grid';

function Grid({ component, children, ...gridProps }) {
  return (
    <MuiGrid component={component} {...gridProps}>
      {children}
    </MuiGrid>
  );
}

Grid.defaultProps = {
  component: 'div'
};

Grid.propTypes = {
  component: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Grid;
