import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import CompareIcon from '@material-ui/icons/CompareArrows';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import ExploreIcon from '@material-ui/icons/Explore';
import CloseIcon from '@material-ui/icons/Close';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import RemoveIcon from '@material-ui/icons/Remove';
import TranslateIcon from '@material-ui/icons/Translate';
import LaunchIcon from '@material-ui/icons/Launch';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import InputIcon from '@material-ui/icons/Input';
import PostAddIcon from '@material-ui/icons/PostAdd';
import InfoIcon from '@material-ui/icons/Info';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddCircle from '@material-ui/icons/AddCircle';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CutIcon from '@material-ui/icons/Crop';
import DescriptionIcon from '@material-ui/icons/Description';

export {
  AddIcon,
  IconButton,
  CompareIcon,
  EditIcon,
  DeleteIcon,
  SaveIcon,
  CancelIcon,
  EuroSymbolIcon,
  ExploreIcon,
  CloseIcon,
  AttachmentIcon,
  ExpandMoreIcon,
  ExpandLessIcon,
  ChevronRightIcon,
  CheckIcon,
  CheckBoxOutlineBlankIcon,
  CheckBoxIcon,
  RemoveIcon,
  MoreHorizIcon,
  TranslateIcon,
  LaunchIcon,
  CloudUploadIcon,
  CloudDownloadIcon,
  AddToPhotosIcon,
  InputIcon,
  SaveAltIcon,
  PostAddIcon,
  InfoIcon,
  AddBoxIcon,
  AddCircle,
  RemoveCircle,
  ArrowBackIcon,
  CutIcon,
  DescriptionIcon
};
