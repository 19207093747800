import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Criteria, useApi } from 'bcp-data';
import { SelectControlled, SelectMultipleControlled } from 'bcp-material-core';
import { makeStyles } from '@material-ui/core/styles';

import ErrorAlert from 'src/components/Common/ErrorAlert';
import NetworkErrorMessage from 'src/components/Common/NetworkErrorMessage';
import useIsMounted from 'src/hooks/useIsMounted';
import Logger from 'src/utils/Logger';

const styles = makeStyles(() => ({ menuPaper: { maxHeight: '50%' } }));
const isKeyValue = item => item?.key && item?.value;
const canConvertToKeyValue = Model => typeof Model?.toKeyValue === 'function';

function RemoteSelect({
  model: Model,
  multiple,
  criteria,
  hasDependency,
  disabled,
  bypassDisabled,
  onchange,
  ...selectProps
}) {
  const isMounted = useIsMounted();
  const [criteriaInUse, setCriteriaInUse] = useState(null);
  const { getAll, find } = Model.apiHook();
  const { isLoading, error, networkError, apiCall } = useApi(
    criteria ? find : getAll
  );
  const [showNetworkError, setShowNetworkError] = useState(false);
  const [options, setOptions] = useState([]);
  const classes = styles();

  useEffect(() => {
    if (
      isMounted.current &&
      (!disabled || bypassDisabled) &&
      (hasDependency || criteria !== criteriaInUse || !options?.length)
    ) {
      if (criteria) {
        setCriteriaInUse(criteria);
        apiCall(criteria)
          .then(response => {
            let data = response.items;
            if (
              data.length &&
              !isKeyValue(data[0]) &&
              canConvertToKeyValue(Model)
            ) {
              data = data.map(item => Model.toKeyValue(item));
            }
            if (isMounted.current) {
              setOptions(data);
            }
          })
          .catch(Logger.error);
      } else {
        apiCall()
          .then(response => {
            let data = response;
            if (
              data.length &&
              !isKeyValue(data[0]) &&
              canConvertToKeyValue(Model)
            ) {
              data = data.map(item => Model.toKeyValue(item));
            }
            if (isMounted.current) {
              setOptions(data);
            }
          })
          .catch(reason => Logger.error(JSON.stringify(reason)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Model, disabled, criteria, isMounted]);

  useEffect(() => {
    if (isMounted.current && networkError) {
      setShowNetworkError(true);
    }
  }, [networkError, isMounted]);

  return (
    <>
      {error && <ErrorAlert error={error} />}
      {multiple ? (
        <SelectMultipleControlled
          options={options}
          disabled={isLoading || !!error || !!disabled || !options?.length}
          MenuProps={{ classes: { paper: classes.menuPaper } }}
          {...selectProps}
        />
      ) : (
        <SelectControlled
          options={options}
          disabled={isLoading || !!error || !!disabled || !options?.length}
          MenuProps={{ classes: { paper: classes.menuPaper } }}
          {...selectProps}
        />
      )}
      <NetworkErrorMessage
        open={showNetworkError}
        setOpen={setShowNetworkError}
      />
    </>
  );
}

RemoteSelect.defaultProps = {
  multiple: false,
  criteria: null,
  hasDependency: false,
  disabled: false,
  bypassDisabled: false
};

RemoteSelect.propTypes = {
  model: PropTypes.oneOfType([PropTypes.any]).isRequired,
  multiple: PropTypes.bool,
  criteria: PropTypes.instanceOf(Criteria),
  hasDependency: PropTypes.bool,
  disabled: PropTypes.bool,
  bypassDisabled: PropTypes.bool
};

export default RemoteSelect;
