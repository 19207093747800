/* eslint-disable */
import mock from 'src/services/api/mock/mockAdapter';

const plots = [
  {
    polygon: 544,
    numberPlot: 0,
    surface: 0,
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-6.965288720028799, 42.307623469726195],
          [-6.965277102871309, 42.30761165074174],
          [-6.965227850700379, 42.30752815962971],
          [-6.965206745746019, 42.30737272046927],
          [-6.965093048034649, 42.30725440329551],
          [-6.964966925422356, 42.307116481684474],
          [-6.9649560617987305, 42.30704844842467],
          [-6.964882760886836, 42.306892081629165],
          [-6.9648479832495225, 42.30675578248768],
          [-6.964770313120239, 42.306735020639564],
          [-6.964650998751114, 42.306791050677006],
          [-6.964433222144828, 42.306661192899035],
          [-6.964543234162288, 42.30648869905154],
          [-6.964531433410386, 42.30644972292001],
          [-6.964798031122923, 42.30628000921297],
          [-6.964727541758201, 42.30603646897522],
          [-6.9647347206320465, 42.305813690576926],
          [-6.964821440769691, 42.305553558720945],
          [-6.964877380374978, 42.30543825391088],
          [-6.9646446871259515, 42.305366280230984],
          [-6.964229936612968, 42.305271690109244],
          [-6.96394205947119, 42.305274792104406],
          [-6.963826479362377, 42.30547720500999],
          [-6.963764480539421, 42.30558719280113],
          [-6.963552673082339, 42.306014293354266],
          [-6.963533011627543, 42.30605996828776],
          [-6.963523722620114, 42.30608978484321],
          [-6.963349082010513, 42.30679829972147],
          [-6.963189798828031, 42.307323737707826],
          [-6.963053459094487, 42.307656920989736],
          [-6.9629942350597425, 42.30793553958003],
          [-6.9629934785602545, 42.30819070721024],
          [-6.963024840865967, 42.30821040421589],
          [-6.963052166630174, 42.308249549204575],
          [-6.963060046611764, 42.30828743636532],
          [-6.963058708061666, 42.3083308557784],
          [-6.963088799897228, 42.30836715009551],
          [-6.963126939470518, 42.308394978919125],
          [-6.963159882557477, 42.30842356192301],
          [-6.9631952552630585, 42.30845893511305],
          [-6.963218977785666, 42.30849221190197],
          [-6.96323566001991, 42.308538983717845],
          [-6.9632617288120295, 42.308580056041706],
          [-6.963289223500164, 42.308616267235706],
          [-6.96332971078256, 42.308653734113555],
          [-6.96347388647936, 42.308775023319214],
          [-6.963713872677408, 42.30896873296511],
          [-6.96383424330349, 42.3090743477853],
          [-6.963910606754344, 42.30912883028408],
          [-6.9640761553564845, 42.309206280687626],
          [-6.964206248211303, 42.30925497925728],
          [-6.964228628923891, 42.30926780361719],
          [-6.964209005956097, 42.308899156046365],
          [-6.964546960232507, 42.30824995327627],
          [-6.965288720028799, 42.307623469726195]
        ]
      ]
    },
    id: 'b0c1f199-25aa-4bd9-cebc-08d800c42ab3'
  },
  {
    polygon: 532,
    numberPlot: 0,
    surface: 0,
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-6.965405525031773, 42.307742303845096],
            [-6.965288720028799, 42.307623469726195],
            [-6.964546960232507, 42.30824995327627],
            [-6.964209005956097, 42.308899156046365],
            [-6.964228628923891, 42.30926780361719],
            [-6.964282946827867, 42.30929881739385],
            [-6.964322426680143, 42.309328574314264],
            [-6.964444474066184, 42.30944173257514],
            [-6.964500971199059, 42.30950753139814],
            [-6.964573312246521, 42.30960643978226],
            [-6.964615056104543, 42.30964776401898],
            [-6.964671301584645, 42.309679783546855],
            [-6.964757470704993, 42.30971700017406],
            [-6.964833330005358, 42.30974734250172],
            [-6.9648848818528615, 42.30978589953571],
            [-6.964941126963556, 42.30979478344273],
            [-6.964944146583869, 42.30979644906111],
            [-6.964972503125517, 42.30960701188965],
            [-6.965099194748568, 42.30914668913568],
            [-6.965222827736488, 42.30878119802069],
            [-6.965350268584669, 42.30851852597883],
            [-6.9651217717970715, 42.308517249967366],
            [-6.964980508654926, 42.308520562518225],
            [-6.9649548584351955, 42.308464809814964],
            [-6.964937983709589, 42.30838010847809],
            [-6.964960107259148, 42.3083019216431],
            [-6.9650288058069, 42.308116875814356],
            [-6.965059705689271, 42.30800974033388],
            [-6.9651622483012385, 42.30799118812099],
            [-6.965257422540148, 42.30795795370863],
            [-6.965349333636082, 42.30790428783638],
            [-6.965390864241552, 42.307832265728756],
            [-6.965405525031773, 42.307742303845096]
          ]
        ],
        [
          [
            [-6.964929027079634, 42.309964386122864],
            [-6.964883628189174, 42.30995966175897],
            [-6.964835766274024, 42.309939125677325],
            [-6.964813133364733, 42.30991096215964],
            [-6.964814054410109, 42.30985798664247],
            [-6.964820088617941, 42.30983082831143],
            [-6.9648491741245415, 42.30979855698928],
            [-6.964815728245549, 42.30976486114971],
            [-6.964743138045649, 42.30973585925737],
            [-6.964655124062706, 42.309697721005364],
            [-6.96459418526647, 42.30966318751046],
            [-6.964548836662715, 42.309618175388046],
            [-6.964475824753708, 42.30951851211068],
            [-6.964420752923674, 42.30945430466661],
            [-6.964300633723638, 42.309342907905155],
            [-6.964263667553663, 42.30931507869803],
            [-6.96423017480081, 42.30929598989931],
            [-6.9642688128969725, 42.31002031326036],
            [-6.964290563813664, 42.310099919427806],
            [-6.964328122704573, 42.310237385663164],
            [-6.964328771514779, 42.31023976656832],
            [-6.964329044947216, 42.31024076924873],
            [-6.964335748668763, 42.310265304381325],
            [-6.964587237694423, 42.31118580105182],
            [-6.964624089122866, 42.31191984187702],
            [-6.964899994092364, 42.31136112662363],
            [-6.964922078145404, 42.31117174565534],
            [-6.964907327172632, 42.31063774675574],
            [-6.964929027079634, 42.309964386122864]
          ]
        ],
        [
          [
            [-6.964487828213465, 42.31243833964528],
            [-6.964419369415035, 42.312478619205216],
            [-6.964418652087271, 42.312479040538165],
            [-6.964416783257788, 42.3124801402594],
            [-6.964231380992373, 42.312589224454136],
            [-6.964227384119449, 42.31263898073624],
            [-6.964227277802059, 42.31264028376424],
            [-6.964219421430031, 42.31273805617663],
            [-6.964219354016422, 42.312742518170964],
            [-6.9642234088491595, 42.312740078514295],
            [-6.964480915144419, 42.3124641792247],
            [-6.964487828213465, 42.31243833964528]
          ]
        ]
      ]
    },
    id: '10bb4e07-e57a-411c-cebd-08d800c42ab3'
  },
  {
    polygon: 553,
    numberPlot: 0,
    surface: 0,
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-6.964624089122866, 42.31191984187702],
          [-6.964587237694423, 42.31118580105182],
          [-6.964335748668763, 42.310265304381325],
          [-6.964329044947216, 42.31024076924873],
          [-6.964328771514779, 42.31023976656832],
          [-6.964328122704573, 42.310237385663164],
          [-6.964290563813664, 42.310099919427806],
          [-6.9642688128969725, 42.31002031326036],
          [-6.96423017480081, 42.30929598989931],
          [-6.964190616160352, 42.30927334600157],
          [-6.964061864369433, 42.30922512421683],
          [-6.96389261013081, 42.309146024779245],
          [-6.963877030230828, 42.30913488723678],
          [-6.963874425902624, 42.30913302453916],
          [-6.963813037389038, 42.30908910056761],
          [-6.96369199598775, 42.308983066754585],
          [-6.963637640992502, 42.308939185684665],
          [-6.963635436821897, 42.308937405737765],
          [-6.963498066589744, 42.3088264752526],
          [-6.963452277013892, 42.30878949869667],
          [-6.9633074568102895, 42.30866769099517],
          [-6.963271196655928, 42.30863417254291],
          [-6.963270526346192, 42.30863355095102],
          [-6.963265083735185, 42.30862850474691],
          [-6.963235829045403, 42.30858994692513],
          [-6.963208250469824, 42.308546444163774],
          [-6.9631916515465, 42.30850017453784],
          [-6.9631803670885795, 42.30848436725432],
          [-6.963178543400599, 42.308481813270596],
          [-6.963170519144359, 42.308470554127695],
          [-6.963158043656606, 42.30845800733301],
          [-6.96315764062967, 42.30845760032122],
          [-6.963137150387168, 42.30843699875477],
          [-6.963115870702131, 42.30841856702386],
          [-6.963114567573225, 42.3084174406772],
          [-6.963105633944045, 42.30840970008356],
          [-6.9630715451989165, 42.3083848898807],
          [-6.963068704911848, 42.30838282124547],
          [-6.963065832611039, 42.308380729527215],
          [-6.963060477611483, 42.30837563894142],
          [-6.963059881986304, 42.3083750718087],
          [-6.963046256680437, 42.30836210111692],
          [-6.963033318857189, 42.3083497838417],
          [-6.963018536720798, 42.30834713826665],
          [-6.962997015727853, 42.30834326164082],
          [-6.962970225521882, 42.30833577287013],
          [-6.962969767419325, 42.308335644053734],
          [-6.962969286480974, 42.30833550852757],
          [-6.9629044500553485, 42.308642445492374],
          [-6.962868478126482, 42.30923843959446],
          [-6.962930970814354, 42.309637304837594],
          [-6.9631644374010895, 42.30992734111162],
          [-6.963490361224212, 42.31020658999228],
          [-6.9637156656548616, 42.31049026443274],
          [-6.964039312562632, 42.31100599710447],
          [-6.964118991632498, 42.31128400327244],
          [-6.964152642540183, 42.311531854239426],
          [-6.96417901781741, 42.311641605151166],
          [-6.964179806232728, 42.31164488729842],
          [-6.96417992398332, 42.31164537479343],
          [-6.96418109871584, 42.3116502605008],
          [-6.964215537363585, 42.31179356281198],
          [-6.964224487464167, 42.31187228151463],
          [-6.9642440844144415, 42.312044609245675],
          [-6.964247102723186, 42.312195018208605],
          [-6.964250277368841, 42.312353073025626],
          [-6.96423598332449, 42.312531715168134],
          [-6.964231380992373, 42.312589224454136],
          [-6.964416783257788, 42.3124801402594],
          [-6.964418652087271, 42.312479040538165],
          [-6.964419369415035, 42.312478619205216],
          [-6.964487828213465, 42.31243833964528],
          [-6.964495359286032, 42.31241019118851],
          [-6.964557406208448, 42.31209649475465],
          [-6.964602195095561, 42.311965855542624],
          [-6.964602093315216, 42.311964383115296],
          [-6.964604078066591, 42.31196036402156],
          [-6.964624089122866, 42.31191984187702]
        ]
      ]
    },
    id: '4ab17852-c8fe-4ccc-cebe-08d800c42ab3'
  },
  {
    polygon: 926,
    numberPlot: 0,
    surface: 0,
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-6.962534534401702, 42.31363974260968],
          [-6.962456366999471, 42.3136337413817],
          [-6.96143177851494, 42.31396763616415],
          [-6.9603292742311265, 42.31402347427842],
          [-6.959315295491458, 42.31445181514652],
          [-6.958618823170266, 42.31530217526417],
          [-6.958893357856679, 42.31509952680783],
          [-6.959410160174099, 42.31483310560205],
          [-6.960449302842585, 42.314430912850874],
          [-6.961118154334513, 42.314297747403266],
          [-6.961909824142344, 42.31399268483707],
          [-6.962427774623859, 42.313690004627176],
          [-6.962534534401702, 42.31363974260968]
        ]
      ]
    },
    id: '9390c263-efde-40f5-cebf-08d800c42ab3'
  },
  {
    polygon: 891,
    numberPlot: 0,
    surface: 0,
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-6.962456366999461, 42.3136337413817],
          [-6.96240051173154, 42.31362945244731],
          [-6.96190858254287, 42.313569187412384],
          [-6.961541166248333, 42.313507820546405],
          [-6.961195480638138, 42.31343710663571],
          [-6.960404785627683, 42.313138951241726],
          [-6.960358753016507, 42.31348678511681],
          [-6.960107810812749, 42.313714753999115],
          [-6.959794401045522, 42.31372208927213],
          [-6.959268159209067, 42.31385476375342],
          [-6.9578075254585485, 42.31382874834326],
          [-6.957619226290241, 42.31391103963709],
          [-6.957627468931871, 42.31391624946669],
          [-6.957628901741868, 42.313917155746516],
          [-6.95762931912537, 42.31391741894323],
          [-6.957633395267424, 42.31391999513529],
          [-6.95765004840082, 42.31393051504334],
          [-6.957634737908512, 42.313953924345626],
          [-6.957624934239466, 42.313952634769684],
          [-6.957624101786794, 42.313952524477536],
          [-6.95760307839686, 42.31394975889106],
          [-6.957600227674443, 42.31394827350044],
          [-6.957597385266294, 42.31394679366128],
          [-6.957593039332957, 42.31394453055936],
          [-6.957570023419141, 42.31393254192259],
          [-6.957154547760355, 42.314114112196556],
          [-6.9563712283351995, 42.31412597471094],
          [-6.955611975371717, 42.31407786306859],
          [-6.955656143528176, 42.31414750153073],
          [-6.95574238973824, 42.3142580618102],
          [-6.95574968646616, 42.31426741190165],
          [-6.9558484124859445, 42.31439394550707],
          [-6.9559085803257314, 42.31447106018909],
          [-6.956002981842067, 42.31459266431029],
          [-6.956005085548605, 42.314595372892185],
          [-6.956051877174212, 42.314655623687116],
          [-6.956426230882257, 42.31494656456788],
          [-6.956585848784504, 42.315068120529844],
          [-6.956603181716377, 42.31508245938541],
          [-6.956696597305187, 42.315159720568104],
          [-6.956751082278299, 42.31523454153308],
          [-6.956751233391729, 42.315247044935354],
          [-6.95675126017344, 42.31524925449262],
          [-6.956751278143525, 42.31525076145412],
          [-6.956751371364457, 42.31525852956717],
          [-6.956751716142579, 42.31528717089989],
          [-6.956752036474193, 42.315313717988886],
          [-6.956741738226537, 42.31536295098461],
          [-6.956739403071656, 42.3153741042518],
          [-6.956698636137112, 42.315409574759286],
          [-6.956691860243751, 42.315415467928744],
          [-6.956480596420495, 42.315526234378886],
          [-6.956462448895328, 42.315535748590285],
          [-6.956456063582198, 42.31553909561418],
          [-6.9563757882131805, 42.31558118411179],
          [-6.956351637945242, 42.31559384591648],
          [-6.956350802284376, 42.315594284000404],
          [-6.95634953953749, 42.315594945914384],
          [-6.956345426613822, 42.315597103278144],
          [-6.956156762829295, 42.31569601900573],
          [-6.956092413292747, 42.31574859824456],
          [-6.9560913787670815, 42.31574944433763],
          [-6.956076658986298, 42.31576147093907],
          [-6.9560603213005345, 42.31577482223424],
          [-6.95603995400725, 42.315791467074],
          [-6.956034568733953, 42.315801253817135],
          [-6.9560312270611195, 42.315807323457],
          [-6.956030351499058, 42.31580891895036],
          [-6.956030076844111, 42.315809419268234],
          [-6.956027255557014, 42.31581455709964],
          [-6.956009826957395, 42.31584631264032],
          [-6.956011313188035, 42.31589088456199],
          [-6.95601136429138, 42.31589237950629],
          [-6.956011592592036, 42.315899120684726],
          [-6.956018257204876, 42.31590723832313],
          [-6.95602535489682, 42.31591588420882],
          [-6.956044153253976, 42.31593878390868],
          [-6.95604536381693, 42.31594025900281],
          [-6.956073113899545, 42.31597405662806],
          [-6.956082075676324, 42.31598351923878],
          [-6.956082702261615, 42.3159841797172],
          [-6.956083021370199, 42.315984517714845],
          [-6.9561315449932755, 42.31603571628009],
          [-6.956142431188346, 42.316047055750936],
          [-6.956143209466818, 42.31604786572545],
          [-6.956159035233112, 42.3160643490286],
          [-6.95616832267839, 42.31607402276771],
          [-6.956183082384395, 42.31608940278188],
          [-6.956188616157838, 42.3161395772233],
          [-6.956183490607022, 42.31615153625755],
          [-6.956183234634071, 42.31615213416968],
          [-6.956134797298137, 42.316265143007406],
          [-6.956024836515171, 42.316479501829825],
          [-6.955941935487055, 42.31660620415375],
          [-6.955934252909484, 42.31661794556972],
          [-6.955907649737585, 42.316658606144514],
          [-6.955848552683383, 42.316708201679916],
          [-6.955732273089598, 42.316805781474045],
          [-6.955731536253881, 42.31680638972093],
          [-6.955730674822235, 42.3168070984098],
          [-6.955730187324048, 42.31680750127247],
          [-6.955556979593568, 42.31695025092156],
          [-6.955518228924814, 42.317010601666965],
          [-6.955493345853201, 42.317049354811886],
          [-6.955491119633089, 42.31705353783995],
          [-6.955482073641939, 42.31707054300699],
          [-6.955480351168212, 42.31707378402633],
          [-6.955473487479182, 42.317086688233125],
          [-6.955772934338812, 42.31701388181147],
          [-6.9560157821197635, 42.316932153002206],
          [-6.956904981123182, 42.31658532678476],
          [-6.957184912709144, 42.31638131348559],
          [-6.957606362090286, 42.31615523399496],
          [-6.958013835481238, 42.31584899074358],
          [-6.958467180841827, 42.31543484260673],
          [-6.958505377504768, 42.31538591541132],
          [-6.958618823170253, 42.31530217526421],
          [-6.959315295491447, 42.314451815146505],
          [-6.9603292742311265, 42.31402347427843],
          [-6.961431778514928, 42.31396763616415],
          [-6.962456366999461, 42.3136337413817]
        ]
      ]
    },
    id: '1c6563af-6195-4292-cec0-08d800c42ab3'
  },
  {
    polygon: 1110,
    numberPlot: 0,
    surface: 0,
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-6.953311301733022, 42.31697475247742],
          [-6.953299844170903, 42.316959750758805],
          [-6.953111770634494, 42.316828682801216],
          [-6.952713744832337, 42.316562893354266],
          [-6.9522612980409635, 42.31618403374644],
          [-6.9518439701994685, 42.31579717789057],
          [-6.951352784403952, 42.31553834014413],
          [-6.950811522513973, 42.315390701659034],
          [-6.95035839680227, 42.315391222053684],
          [-6.950040131737015, 42.31553211601512],
          [-6.949858964622606, 42.31574444341444],
          [-6.949657371330609, 42.31587017812797],
          [-6.949194445167569, 42.316172313144186],
          [-6.948926112598202, 42.316564156364684],
          [-6.948621260792972, 42.31700708173579],
          [-6.948588516477751, 42.31729966732333],
          [-6.948710605872607, 42.31741347061086],
          [-6.948751852853213, 42.31745191797663],
          [-6.9489766119956125, 42.31731242999357],
          [-6.949699548037132, 42.317069180719116],
          [-6.950007055841629, 42.31698345472556],
          [-6.950507119961602, 42.31687712328411],
          [-6.951436665596006, 42.31687005149913],
          [-6.9517687490806415, 42.31689209526266],
          [-6.952076813520633, 42.31691699688474],
          [-6.9526223036106884, 42.3170348008734],
          [-6.952656622072559, 42.31703929347374],
          [-6.953104891474689, 42.31708502923922],
          [-6.953225235834782, 42.3170944601622],
          [-6.953302793695793, 42.317007111753725],
          [-6.953311301733022, 42.31697475247742]
        ]
      ]
    },
    id: '1a83d1ca-241b-427f-cec1-08d800c42ab3'
  },
  {
    polygon: 882,
    numberPlot: 0,
    surface: 0,
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-6.952239686321055, 42.31277815922139],
          [-6.952090888292734, 42.31263753288889],
          [-6.951922164571448, 42.3124833471928],
          [-6.951781721411354, 42.31232507822154],
          [-6.95126360059017, 42.31210030974816],
          [-6.951239545458589, 42.312090005133065],
          [-6.951156221878511, 42.31205431425476],
          [-6.95114795977918, 42.31205077505733],
          [-6.950963584885683, 42.31197179876359],
          [-6.950814243173927, 42.311887030161905],
          [-6.950818570028096, 42.311753697255654],
          [-6.950821565520849, 42.31166138999545],
          [-6.9508124072386765, 42.311517553196516],
          [-6.950615620274184, 42.31161665866524],
          [-6.950476512184463, 42.31166100344925],
          [-6.950384144039577, 42.31188493110594],
          [-6.950093526479392, 42.3122812401731],
          [-6.94993961495787, 42.312459116136736],
          [-6.949773599148872, 42.312683896418974],
          [-6.949633955510036, 42.313074069989675],
          [-6.949405375065357, 42.313596159830034],
          [-6.949225210788007, 42.31393063400245],
          [-6.948841818018755, 42.31433215110375],
          [-6.948547868984128, 42.31458605354803],
          [-6.948550788968542, 42.314649188387286],
          [-6.948450832780477, 42.31486310657413],
          [-6.948382586760318, 42.314995623970944],
          [-6.948298311401448, 42.31508471298299],
          [-6.948233287401843, 42.31511806222338],
          [-6.948137392817081, 42.31520694223023],
          [-6.948022767025962, 42.31533493685729],
          [-6.947958040201596, 42.31547863802647],
          [-6.947821916664848, 42.315657032075954],
          [-6.947813725420663, 42.31566776781516],
          [-6.947826234225649, 42.315674682942195],
          [-6.9478700405421305, 42.31568365422686],
          [-6.947870808357134, 42.31568381206302],
          [-6.947872520018788, 42.315684162406484],
          [-6.947887211630264, 42.31568294310497],
          [-6.947889774020206, 42.31568272961733],
          [-6.94789498268174, 42.31568229783963],
          [-6.947897886925219, 42.315682057151164],
          [-6.9478989344902145, 42.315681970539764],
          [-6.947900219352526, 42.315681862960915],
          [-6.9479007668559785, 42.31568181782846],
          [-6.947927876064184, 42.31567956814895],
          [-6.947959690591575, 42.31566844210705],
          [-6.947985687425634, 42.31565934984195],
          [-6.948040472873531, 42.315614179498795],
          [-6.948042067540066, 42.31561286530784],
          [-6.948068107458474, 42.3155913946695],
          [-6.948086483965514, 42.31557644199478],
          [-6.948086930165793, 42.315576078950734],
          [-6.948088777298744, 42.31557457565771],
          [-6.948089593705857, 42.315573910347986],
          [-6.948090611982791, 42.31557308204798],
          [-6.948091539304305, 42.31557232686644],
          [-6.948192865504184, 42.31548987688488],
          [-6.94827631914663, 42.31540462585474],
          [-6.948277060861251, 42.315403869150494],
          [-6.948277790097845, 42.31540312302964],
          [-6.948379749380685, 42.31529897075813],
          [-6.948714925803011, 42.31492061133725],
          [-6.9489936512630965, 42.31462069022464],
          [-6.948994545236075, 42.31461972821011],
          [-6.948996604770858, 42.314617511855076],
          [-6.948997456021253, 42.31461659500473],
          [-6.9493296511621345, 42.314307607420766],
          [-6.949473113483981, 42.3141839420901],
          [-6.949474118337099, 42.314183077514826],
          [-6.94947532173544, 42.31418203998112],
          [-6.949476450239134, 42.31418106684865],
          [-6.949491669796506, 42.31416795044984],
          [-6.949492292724777, 42.31416741315084],
          [-6.949493308871465, 42.314166537070136],
          [-6.949582092023985, 42.314111394337225],
          [-6.949628946017836, 42.314043787915544],
          [-6.949629257854467, 42.31404333961014],
          [-6.9496298001752566, 42.31404255681612],
          [-6.9496308326419065, 42.31404106594201],
          [-6.949641033392102, 42.31402634860474],
          [-6.949681880249788, 42.31398615589226],
          [-6.949685772221104, 42.313982324272416],
          [-6.949692902963971, 42.31397530856011],
          [-6.949804984535684, 42.31386502309475],
          [-6.949853531905152, 42.31381789773567],
          [-6.949873339718996, 42.313798670181136],
          [-6.949973873247537, 42.313701082161025],
          [-6.950025628287874, 42.31365205807314],
          [-6.950027293270938, 42.31365048124656],
          [-6.950028388634494, 42.31364944447646],
          [-6.950029663049631, 42.31364823709999],
          [-6.950032092994173, 42.31364593369169],
          [-6.950032460974099, 42.3136455872522],
          [-6.950146507654731, 42.31353756187644],
          [-6.9501531335971585, 42.31353316044559],
          [-6.950318012679581, 42.313423644821704],
          [-6.950432052261156, 42.31336530305873],
          [-6.9504326543843495, 42.31336499502592],
          [-6.950433666153502, 42.31336447728225],
          [-6.950434405249033, 42.313364098753],
          [-6.950445991224578, 42.31335817038384],
          [-6.950492853159808, 42.313334196889606],
          [-6.950616823090175, 42.313296477064505],
          [-6.950626342539785, 42.313293580846526],
          [-6.950627103579681, 42.313293348599494],
          [-6.950678759604583, 42.31327763154466],
          [-6.950763573980114, 42.31326290351234],
          [-6.9507645562800215, 42.31326273285599],
          [-6.950765716738928, 42.313262531163325],
          [-6.9507663829724144, 42.3132624160947],
          [-6.950769184659479, 42.31326192944695],
          [-6.950770811248018, 42.313261646931835],
          [-6.950945378200602, 42.31323133335254],
          [-6.951028250955255, 42.31322999510041],
          [-6.951033707090064, 42.31322701540847],
          [-6.951314267985206, 42.31307379863493],
          [-6.951795940743936, 42.312870839114495],
          [-6.952114388174181, 42.312817760873514],
          [-6.952197552849914, 42.312790679902726],
          [-6.952239686321055, 42.31277815922139]
        ]
      ]
    },
    id: '18b890e1-45a7-43b1-cec2-08d800c42ab3'
  },
  {
    polygon: 890,
    numberPlot: 0,
    surface: 0,
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-6.950904452084312, 42.31329760179597],
          [-6.950702450045824, 42.313332719232086],
          [-6.950530029692685, 42.313385109384306],
          [-6.95036616879041, 42.31346899190154],
          [-6.950203464009942, 42.31357712538848],
          [-6.950170884739846, 42.31360796610724],
          [-6.950152488870238, 42.31362538001575],
          [-6.950035214426114, 42.313736396073736],
          [-6.949935089574656, 42.31383361862365],
          [-6.949931660431938, 42.31383694881151],
          [-6.949929699679753, 42.31383885265431],
          [-6.9499175730411595, 42.31385062855812],
          [-6.94991724607124, 42.31385094511157],
          [-6.94991640787748, 42.313851759538345],
          [-6.949916048325964, 42.31385210792929],
          [-6.949905061862306, 42.31386277491866],
          [-6.949904480478302, 42.31386334088041],
          [-6.949866828916778, 42.31389990121951],
          [-6.949707017693077, 42.314057160082385],
          [-6.949693486865764, 42.314076640371546],
          [-6.949689795469794, 42.314081955203186],
          [-6.949681305300493, 42.31409417796538],
          [-6.949676581494311, 42.31410098187102],
          [-6.949650702312941, 42.3141382399152],
          [-6.949643877080845, 42.31414806544045],
          [-6.949595890448165, 42.31417787301787],
          [-6.949547903770402, 42.31420768057407],
          [-6.949419251663432, 42.31431858341214],
          [-6.949417063334475, 42.31432046966624],
          [-6.94938936647889, 42.31434434519687],
          [-6.949059813182597, 42.31465093609642],
          [-6.949011908785565, 42.31470247185946],
          [-6.948996237346197, 42.31471933184911],
          [-6.948972121607774, 42.31474527690561],
          [-6.948965383516794, 42.31475252653914],
          [-6.948779284296521, 42.314952739692046],
          [-6.948546918571039, 42.31521507739783],
          [-6.948545486390495, 42.3152166943912],
          [-6.9484435974663, 42.31533172686237],
          [-6.948253082623827, 42.31552634595734],
          [-6.948171705531453, 42.31559261066701],
          [-6.948170512737811, 42.31559358173486],
          [-6.9481644864372445, 42.315598488190695],
          [-6.948125702331678, 42.31563006917237],
          [-6.948106310426027, 42.31564606003584],
          [-6.9481027606759405, 42.31564898815877],
          [-6.9481016909218285, 42.3156498695711],
          [-6.948032929473177, 42.31570657360479],
          [-6.947994040168927, 42.3157201062956],
          [-6.947993548021957, 42.3157202775976],
          [-6.947981437082404, 42.31572449155218],
          [-6.947974668544503, 42.31572684692651],
          [-6.947966541447528, 42.315729673288324],
          [-6.947965325201415, 42.31573009639244],
          [-6.947962686516665, 42.31573101455654],
          [-6.947961484969269, 42.31573143342094],
          [-6.947959623173538, 42.31573208091545],
          [-6.947949232925749, 42.315735695277496],
          [-6.947866377774409, 42.315742586269806],
          [-6.947809826610258, 42.315731011123326],
          [-6.9477916419903645, 42.31572728953264],
          [-6.94777520270206, 42.31571825345132],
          [-6.947703832618313, 42.31581178409097],
          [-6.947475112298192, 42.316011876749],
          [-6.9472033361017145, 42.31621505677059],
          [-6.947218285482008, 42.31623744950138],
          [-6.9472186164003356, 42.31623794533339],
          [-6.94721917752965, 42.31623878750174],
          [-6.947250338767015, 42.31628546153042],
          [-6.9472509658617945, 42.316286402140626],
          [-6.947251309564558, 42.31628691531216],
          [-6.947259395177081, 42.31629902767997],
          [-6.9472621724386165, 42.31630164852052],
          [-6.947527290087981, 42.31655189011743],
          [-6.947590675018847, 42.3165872347206],
          [-6.947615406662244, 42.31660102477904],
          [-6.947616929813811, 42.316601874264556],
          [-6.9477857573547634, 42.31669601319969],
          [-6.9479791015403585, 42.31688867998708],
          [-6.948293701485349, 42.31712849908414],
          [-6.948302536283032, 42.31709938265831],
          [-6.948328019769203, 42.31701540481723],
          [-6.948471908352119, 42.31669772689705],
          [-6.948697940429403, 42.316381518834035],
          [-6.948881412466887, 42.31611029907989],
          [-6.949144551830729, 42.31591675503384],
          [-6.94937690128462, 42.315768388288014],
          [-6.949469792685428, 42.3157090721098],
          [-6.94971313499449, 42.315492297400844],
          [-6.949955980752913, 42.315290763400434],
          [-6.950350618546804, 42.31516056966565],
          [-6.950318200579986, 42.31498846440032],
          [-6.950386585687005, 42.314669125779005],
          [-6.950510627627879, 42.3144225498651],
          [-6.950643056620332, 42.314237174677565],
          [-6.950212473443511, 42.31384160066408],
          [-6.950459797500312, 42.313540426319776],
          [-6.950904452084312, 42.31329760179597]
        ]
      ]
    },
    id: '64a71eeb-44ff-4909-cec3-08d800c42ab3'
  },
  {
    polygon: 929,
    numberPlot: 0,
    surface: 0,
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-6.948193147210367, 42.317311750217584],
            [-6.948080603858639, 42.317215719648935],
            [-6.947800203946606, 42.31700191803429],
            [-6.947731923035141, 42.31693388645072],
            [-6.94757495202006, 42.31679628158813],
            [-6.947318362604115, 42.31665322280428],
            [-6.947317808371882, 42.31665291479314],
            [-6.947317293515874, 42.31665262729952],
            [-6.947314909848543, 42.316651298610616],
            [-6.947313545242051, 42.316650536613125],
            [-6.94725356429884, 42.316591523913544],
            [-6.947245237447842, 42.316583321967826],
            [-6.947243477334641, 42.316581587484265],
            [-6.947239796027704, 42.316577959835115],
            [-6.947237746597475, 42.31657594279714],
            [-6.947076114306317, 42.316416731245575],
            [-6.947066628281616, 42.31640253329804],
            [-6.947001569397314, 42.31630516554606],
            [-6.94699403540555, 42.31629389065654],
            [-6.946934942751964, 42.316205453430705],
            [-6.9469378722190775, 42.31611555237627],
            [-6.946937904588747, 42.31611455693457],
            [-6.946937964996517, 42.316112699256806],
            [-6.9469244275988045, 42.315807920966066],
            [-6.9469651847672225, 42.315265386060986],
            [-6.947035442565702, 42.31476340944742],
            [-6.947116186904352, 42.31417584201169],
            [-6.947242389000437, 42.313611961927165],
            [-6.947354087993195, 42.31254111871357],
            [-6.946966740261009, 42.31284788356782],
            [-6.946770036700682, 42.313210349707006],
            [-6.946574818935816, 42.313527093535484],
            [-6.946363826481981, 42.313854990627625],
            [-6.946155805031905, 42.31409144432934],
            [-6.945745556408426, 42.31391253570087],
            [-6.945487830302282, 42.313782107139836],
            [-6.945156995521073, 42.313607353881416],
            [-6.9448939254450925, 42.31394289880119],
            [-6.944655017315984, 42.31428294778154],
            [-6.944470871878895, 42.31463227696965],
            [-6.944712113097061, 42.31501434803155],
            [-6.944778060050657, 42.31541400030505],
            [-6.944604624854108, 42.315771161138805],
            [-6.944397033207192, 42.31638287701159],
            [-6.944314920699951, 42.31673151039018],
            [-6.944406504796035, 42.31718309414781],
            [-6.944543302883338, 42.317537860523984],
            [-6.944580440392332, 42.317949901188456],
            [-6.944617910628142, 42.31838483104984],
            [-6.944655121221001, 42.31881675284406],
            [-6.944667777529749, 42.319017061113534],
            [-6.944779242357343, 42.31897730342889],
            [-6.944868170245232, 42.31893561060777],
            [-6.944952480412195, 42.318915511440174],
            [-6.945099016256841, 42.31886324302416],
            [-6.945338865697315, 42.318833231267924],
            [-6.9455389088456805, 42.3188425354999],
            [-6.94556951117872, 42.318844371802484],
            [-6.945623231745775, 42.31883903881606],
            [-6.94571878818231, 42.31883291923761],
            [-6.9457476604145025, 42.31881938162007],
            [-6.945668697424378, 42.31849796632412],
            [-6.945582149008309, 42.318366453300705],
            [-6.945525146906914, 42.31817902799961],
            [-6.945506030409053, 42.31798757899506],
            [-6.94555193793621, 42.31788171679818],
            [-6.9455650575864185, 42.317802223428295],
            [-6.945601140702453, 42.317768257967074],
            [-6.945609628641641, 42.31774868599587],
            [-6.945696016840229, 42.317678951238],
            [-6.945755831288978, 42.31762264754483],
            [-6.945778687485198, 42.317612217102564],
            [-6.945821776710066, 42.31757743514178],
            [-6.946025173053018, 42.317499739508285],
            [-6.946045228166326, 42.31749058804789],
            [-6.946065124849907, 42.317484478544024],
            [-6.946068210160528, 42.31748330005996],
            [-6.946418497906653, 42.31736921532375],
            [-6.946929138448999, 42.317318161984474],
            [-6.94764296216314, 42.317363752784786],
            [-6.947728536849597, 42.31736994988936],
            [-6.947813572535736, 42.317373578783965],
            [-6.947841497714268, 42.317378129469994],
            [-6.948109871559362, 42.31739756358751],
            [-6.948193147210367, 42.317311750217584]
          ]
        ],
        [
          [
            [-6.947362162031099, 42.31253472431456],
            [-6.947354322361231, 42.31253887330559],
            [-6.947354087993195, 42.31254111871357],
            [-6.947362162031099, 42.31253472431456]
          ]
        ]
      ]
    },
    id: '187920ef-4062-4d16-cec4-08d800c42ab3'
  },
  {
    polygon: 1109,
    numberPlot: 0,
    surface: 0,
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-6.948785625700003, 42.317892770083475],
          [-6.948309658930978, 42.31752207536097],
          [-6.947770903764828, 42.31750289919414],
          [-6.947360496020446, 42.31748601986196],
          [-6.946975450141553, 42.31747912396273],
          [-6.94646050465155, 42.317517554652724],
          [-6.945980965389221, 42.31765192663753],
          [-6.945754403461265, 42.31790520287804],
          [-6.945705980175731, 42.31820932552489],
          [-6.9458137466764756, 42.318449531048145],
          [-6.945911778574696, 42.31859425199233],
          [-6.94587669019505, 42.318755950300364],
          [-6.945880899300407, 42.31878706718358],
          [-6.945836810394695, 42.3188560525775],
          [-6.94574822772156, 42.31889767579334],
          [-6.945656963100148, 42.318903539108646],
          [-6.945633067096733, 42.318905074952944],
          [-6.945607520190954, 42.318907597937],
          [-6.945489275991443, 42.31894885682837],
          [-6.945008069405052, 42.31897192501261],
          [-6.944906386295322, 42.31899614710466],
          [-6.944877399233736, 42.31900973738417],
          [-6.944874360402251, 42.319011162522095],
          [-6.944855539718694, 42.319019985587296],
          [-6.9446627229773945, 42.31917231191112],
          [-6.944652798950156, 42.31947712526553],
          [-6.944643183707659, 42.31977241260103],
          [-6.944636207201163, 42.31998668772202],
          [-6.945131888821292, 42.32005276062481],
          [-6.945561449038681, 42.320586569417216],
          [-6.945881184957716, 42.32070667103072],
          [-6.946295567993967, 42.320759844829944],
          [-6.94669954401576, 42.32082908043241],
          [-6.947087399463185, 42.320750248291596],
          [-6.947543903772546, 42.32092998128054],
          [-6.947876111727098, 42.32019251420026],
          [-6.948073456227795, 42.31965278255602],
          [-6.948309302794279, 42.319113739685676],
          [-6.94852195085244, 42.31849803322348],
          [-6.948759955378055, 42.3178923107053],
          [-6.948785625700003, 42.317892770083475]
        ]
      ]
    },
    id: 'b508428e-948f-4eb9-cec5-08d800c42ab3'
  },
  {
    polygon: 0,
    numberPlot: 0,
    surface: 0,
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-6.94728048101284, 42.3213251015384],
          [-6.947021067288046, 42.321206084026684],
          [-6.946884057642545, 42.32107496205527],
          [-6.946829705351233, 42.32096914794104],
          [-6.9468122719370875, 42.320806168487834],
          [-6.9466995440157495, 42.32082908043241],
          [-6.946575497010323, 42.32080782075026],
          [-6.945894421295095, 42.32070836954735],
          [-6.945550028679084, 42.32057237762203],
          [-6.94514079402684, 42.32006382711126],
          [-6.944635445567326, 42.32001008491958],
          [-6.944578173848178, 42.32057495996439],
          [-6.944740701721518, 42.32063625004122],
          [-6.944912430609537, 42.320686983915834],
          [-6.945245379087001, 42.320716780316694],
          [-6.945484913961477, 42.32085450744578],
          [-6.945667309718137, 42.32097214809181],
          [-6.945656707022073, 42.32110062599523],
          [-6.945814052472371, 42.32119875551966],
          [-6.945776538787911, 42.321364874976815],
          [-6.945267111322307, 42.32162737633234],
          [-6.946192613309404, 42.32140568931988],
          [-6.94582183699219, 42.32155154045595],
          [-6.945875103153272, 42.32169069392023],
          [-6.946135601323182, 42.321776375100534],
          [-6.946233638629947, 42.321921096100674],
          [-6.946273915219431, 42.32206478211041],
          [-6.946610965692694, 42.32216613023733],
          [-6.946817113658742, 42.32214599568088],
          [-6.946936199000904, 42.32203852274294],
          [-6.947032710302722, 42.32183533578811],
          [-6.947174455791718, 42.32162342791474],
          [-6.947216680148898, 42.32150981262663],
          [-6.94728048101284, 42.3213251015384]
        ]
      ]
    },
    id: '10859214-a098-40e3-cec6-08d800c42ab3'
  }
];

const noSelfClosing = polygon => {
  if (
    polygon[0][0] === polygon[polygon.length - 1][0] &&
    polygon[0][1] === polygon[polygon.length - 1][1]
  ) {
    polygon.pop();
  }
  return polygon;
};

const parsePolygons = container => {
  if (!Array.isArray(container?.[0]?.[0])) {
    return noSelfClosing(container.map(point => [point[1], point[0]]));
  }
  return container.map(_container => parsePolygons(_container));
};

const parsePlot = (plot, shapes = false) => {
  const parsedPlot = {
    id: plot?.id,
    polygon: plot?.polygon,
    name: 'Rústico de protección forestal',
    usoSigpac: plot?.usoSigpac || 'PO',
    area: plot?.surface,
    precinct: plot?.numberPlot
  };
  if (shapes) {
    parsedPlot.positions = parsePolygons(plot?.geometry?.coordinates);
  }
  return parsedPlot;
};

mock
  .onGet(/\/api\/plots\?idClient=[0-9]+\+?(withShapes=(true|false))?\/?/)
  .reply(config => {
    const shapesParamMatcher = /withShapes=(true|false)/.exec(config.url);
    let includeShapes =
      shapesParamMatcher.length >= 2 ? shapesParamMatcher[1] : false;
    if (typeof includeShapes === 'string') {
      includeShapes = includeShapes === 'true';
    }
    return [
      200,
      {
        data: plots.map(plot => parsePlot(plot, includeShapes)),
        page: 0,
        totalCount: plots.length
      }
    ];
  });

mock.onGet(/\/api\/plots\?(withShapes=(true|false))?\/?/).reply(config => {
  const shapesParamMatcher = /withShapes=(true|false)/.exec(config.url);
  const includeShapes =
    shapesParamMatcher.length >= 2 ? shapesParamMatcher[1] : false;
  return [
    200,
    {
      data: plots.map(plot => parsePlot(plot, includeShapes)),
      page: 0,
      totalCount: plots.length
    }
  ];
});

plots.forEach((plot, index) => {
  mock.onGet(`/api/plots/${plot.id}`).reply(200, {
    data: parsePlot(plots[index], true)
  });
});
