import { KeyValue } from 'bcp-data';

import ClientTypeForm from 'src/components/Masters/ClientTypeForm';
import schema from 'src/components/Masters/ClientTypeForm/schema';
import KeyValueSearchForm from 'src/components/Masters/KeyValueSearchForm';
import searchSchema from 'src/components/Masters/KeyValueSearchForm/schema';
import useModel from 'src/services/api/hooks/useModel';
import mapper from 'src/services/api/entities/masters/mappers/keyValueMapper';

export default class ClientType extends KeyValue {
  static endpoint = 'clientTypes';

  static apiHook = () => useModel(ClientType);

  static mapper = mapper;

  static form = {
    component: ClientTypeForm,
    schema
  };

  static searchForm = {
    component: KeyValueSearchForm,
    schema: searchSchema
  };

  constructor(source, id) {
    super(source);
    this.key = id ?? this.key;
  }

  static fromApi(apiModel) {
    return new ClientType(mapper.fromApi(apiModel));
  }

  static toApi(clientModel) {
    return mapper.toApi(clientModel);
  }

  static empty() {
    return { key: '', value: '' };
  }
}
